import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// import styling
import "bootstrap/dist/css/bootstrap.min.css";
import "./RaeShopItem.css";

const RaeShopItem = ({
  shopItem,
  setNavMenuActive,
  setCartModalActive,
  handleAddToCart,
  setShopItemModalActive,
  shopItemModalActive,
  toggleShopItemModal,
}) => {
  const [chosenVersion, setChosenVersion] = useState("");
  const [chosenSize, setChosenSize] = useState("");
  const [chosenQuantity, setChosenQuantity] = useState(1);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [sizeError, setSizeError] = useState("");
  const [versionError, setVersionError] = useState("");
  const [currentItem, setCurrentItem] = useState("");
  const [chosenStaafjeOfHoop, setChosenStaafjeOfHoop] = useState("");
  const [staafjeOfHoopError, setStaafjeOfHoopError] = useState("");
  const [chosenBelovedInitialsItem, setChosenBelovedInitialsItem] =
    useState("");

  useEffect(() => {
    setCurrentItem(shopItem);
    console.log("shopItem", shopItem);
  }, [shopItem]);

  const changeMainImageIndex = (index) => {
    setMainImageIndex(index);
  };

  const handleVersionChange = (e) => {
    const index = e.currentTarget.value;
    const chosenVersion = currentItem.versie[index];
    setChosenVersion(chosenVersion);
  };

  const handleStaafjeOfHoopChange = (e) => {
    const index = e.currentTarget.value;
    const newChosenStaafjeOfHoop = currentItem.spec.staafjeOfHoop[index];
    setChosenStaafjeOfHoop(newChosenStaafjeOfHoop);
    console.log("newChosenStaafjeOfHoop", newChosenStaafjeOfHoop);
  };

  const addToCart = () => {
    console.log("adding item to cart");
    if (!chosenVersion && currentItem.versie) {
      setVersionError("Kies een versie");
      return;
    }
    if (!chosenSize && currentItem.maat) {
      setSizeError("Kies een maat");
      setVersionError("");
      return;
    }
    if (!chosenStaafjeOfHoop && currentItem.spec.staafjeOfHoop) {
      setStaafjeOfHoopError("Kies staafje of hoop");
      setVersionError("");
      return;
    }
    if (currentItem.naam === "Beloved Initials") {
      setVersionError("");
      setSizeError("");
      const belovedInitialsItem = setBelovedInitials(chosenVersion, chosenSize);
      console.log("this is the belove initials item", belovedInitialsItem);
      handleAddToCart(
        currentItem,
        belovedInitialsItem,
        chosenSize,
        chosenQuantity
      );
      setCartModalActive(true);
      return;
    } else {
      setVersionError("");
      setSizeError("");
      handleAddToCart(
        currentItem,
        chosenVersion,
        chosenSize,
        chosenQuantity,
        chosenStaafjeOfHoop
      );
      setCartModalActive(true);
    }
  };

  if (!currentItem) {
    return <div>Item not found!</div>;
  }

  const setBelovedInitials = (chosenVersion, chosenSize) => {
    const material = chosenVersion.materiaal;
    const size = chosenSize;
    if (size === "S (10mm)" && material === "925 zilver") {
      return currentItem.versiePrijs[0];
    }
    if (size === "S (10mm)" && material === "925 zilver geel goud verguld") {
      return currentItem.versiePrijs[1];
    }
    if (size === "S (10mm)" && material === "14k geel goud") {
      return currentItem.versiePrijs[2];
    }
    if (size === "S (10mm)" && material === "18k geel goud") {
      return currentItem.versiePrijs[3];
    }
    if (size === "M (15mm)" && material === "925 zilver") {
      return currentItem.versiePrijs[4];
    }
    if (size === "M (15mm)" && material === "925 zilver geel goud verguld") {
      return currentItem.versiePrijs[5];
    }
    if (size === "M (15mm)" && material === "14k geel goud") {
      return currentItem.versiePrijs[6];
    }
    if (size === "M (15mm)" && material === "18k geel goud") {
      return currentItem.versiePrijs[7];
    }
    if (size === "L (20mm)" && material === "925 zilver") {
      return currentItem.versiePrijs[8];
    }
    if (size === "L (20mm)" && material === "925 zilver geel goud verguld") {
      return currentItem.versiePrijs[9];
    }
    if (size === "L (20mm)" && material === "14k geel goud") {
      return currentItem.versiePrijs[10];
    }
    if (size === "L (20mm)" && material === "18k geel goud") {
      return currentItem.versiePrijs[11];
    } else {
      return 0;
    }
  };

  return (
    <div key={currentItem.itemId} className='RaeCollection-itemContainer-item'>
      <img
        src={currentItem.afbeelding[0]}
        alt={currentItem.naam}
        value={currentItem.itemId}
        className='RaeCollection-itemContainer-item-image'
        onClick={() => setShopItemModalActive(currentItem.itemId)}
      />
      {shopItemModalActive === currentItem.itemId && (
        <div className='sideModal shopItem'>
          <button
            onClick={() => setShopItemModalActive(null)}
            className='itemModal-closeButton'
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className='sideModal-content'>
            <div className='item-content'>
              <h1 className='item-content-title'>
                <span style={{ fontWeight: "800" }}>{currentItem.naam}</span> |{" "}
                <span style={{ fontWeight: "300" }}>{currentItem.soort}</span>
              </h1>
              <div className='item-content-images'>
                <div className='item-content-images-otherImageGallery'>
                  {currentItem.afbeelding.map((afbeelding, index) => (
                    <img
                      key={index}
                      src={afbeelding}
                      alt={currentItem.naam}
                      className={
                        mainImageIndex === index
                          ? "item-image"
                          : "item-image inactive"
                      }
                      onClick={() => changeMainImageIndex(index)}
                    />
                  ))}
                </div>
                <div className='item-content-images-mainImage'>
                  <img
                    src={currentItem.afbeelding[mainImageIndex]}
                    alt={currentItem.naam}
                    className='item-image'
                  />
                </div>
              </div>
              <div className='item-content-orderForm'>
                <Form className='item-form'>
                  {currentItem.versie[0].materiaal &&
                    currentItem.versie[0].materiaal !== "Cadeaubon" && (
                      <Form.Group className='itemPage-select-group'>
                        <Form.Select
                          className='itemPage-select versions'
                          name='versie'
                          onChange={handleVersionChange}
                          value={currentItem.versie.indexOf(chosenVersion)}
                        >
                          <option value={""}>kies je versie</option>
                          {currentItem.versie.map((versieItem, index) => (
                            <option key={versieItem.materiaal} value={index}>
                              {versieItem.materiaal}
                            </option>
                          ))}
                        </Form.Select>
                        {versionError && (
                          <p className='sizeError' style={{ color: "red" }}>
                            {versionError}
                          </p>
                        )}
                      </Form.Group>
                    )}
                  {currentItem.maat && (
                    <Form.Group>
                      <Form.Select
                        className='itemPage-select sizes'
                        name='Maat'
                        onChange={(e) => {
                          setChosenSize(e.currentTarget.value);
                        }}
                        value={chosenSize}
                      >
                        <option value={""}>kies je maat</option>
                        {currentItem.maat.map((maatItem, index) => (
                          <option key={index} value={maatItem}>
                            {maatItem}
                          </option>
                        ))}
                      </Form.Select>
                      {sizeError && (
                        <p className='sizeError' style={{ color: "red" }}>
                          {sizeError}
                        </p>
                      )}
                    </Form.Group>
                  )}
                  {currentItem.versie[0].prijsBon && (
                    <Form.Group>
                      <Form.Select
                        className='itemPage-select versie'
                        name='PrijsBon'
                        onChange={handleVersionChange}
                        value={currentItem.versie.indexOf(chosenVersion)}
                      >
                        <option value={""}>kies je bedrag</option>
                        {currentItem.versie.map((versieItem, index) => (
                          <option key={index} value={index}>
                            {versieItem.prijsBon}
                          </option>
                        ))}
                      </Form.Select>
                      {sizeError && (
                        <p className='sizeError' style={{ color: "red" }}>
                          {versionError}
                        </p>
                      )}
                    </Form.Group>
                  )}
                  {currentItem.spec.staafjeOfHoop && (
                    <Form.Group>
                      <Form.Select
                        className='itemPage-select versie'
                        name='StaafJeOfHoop'
                        onChange={handleStaafjeOfHoopChange}
                        value={currentItem.spec.staafjeOfHoop.indexOf(
                          chosenStaafjeOfHoop
                        )}
                      >
                        <option value={""}>kies staafje of hoop</option>
                        {currentItem.spec.staafjeOfHoop.map(
                          (staafjeOfHoopItem, index) => (
                            <option key={index} value={index}>
                              {staafjeOfHoopItem}
                            </option>
                          )
                        )}
                      </Form.Select>
                      {sizeError && (
                        <p className='sizeError' style={{ color: "red" }}>
                          {staafjeOfHoopError}
                        </p>
                      )}
                    </Form.Group>
                  )}
                </Form>
                <div className='item-price'>
                  {chosenVersion && currentItem.naam !== "Beloved Initials" && (
                    <h1>€{chosenVersion.prijs * chosenQuantity}</h1>
                  )}
                  {chosenVersion && currentItem.naam === "Beloved Initials" && (
                    <h1>
                      €{setBelovedInitials(chosenVersion, chosenSize).prijs}
                    </h1>
                  )}
                  {!chosenVersion && <h1>€0</h1>}
                  <div className='item-content-orderForm-specs'>
                    {currentItem.spec.verkochtAls && (
                      <p>
                        <span className='bold'>Verkocht als</span>{" "}
                        {currentItem.spec.verkochtAls}
                      </p>
                    )}
                    {currentItem.spec.tePersonaliserenMetSlagletters && (
                      <p>
                        <span className='bold'>Personalisatie:</span>{" "}
                        {currentItem.spec.tePersonaliserenMetSlagletters}
                      </p>
                    )}
                    {currentItem.spec.diameter && (
                      <p>
                        <span className='bold'>Diameter:</span>{" "}
                        {currentItem.spec.diameter}
                      </p>
                    )}
                    {currentItem.spec.breedte && (
                      <p>
                        <span className='bold'>Breedte:</span>{" "}
                        {currentItem.spec.breedte}
                      </p>
                    )}
                    {currentItem.spec.afmetingenPlaatje && (
                      <p>
                        <span className='bold'>Afmetingen Plaatje:</span>{" "}
                        {currentItem.spec.afmetingenPlaatje}
                      </p>
                    )}
                    {currentItem.spec.afmetingenZoetwaterparel && (
                      <p>
                        <span className='bold'>Afmetingen Zoetwaterparel:</span>{" "}
                        {currentItem.spec.afmetingenZoetwaterparel}
                      </p>
                    )}
                    {currentItem.spec.afwerking && (
                      <p>
                        <span className='bold'>Afwerking:</span>{" "}
                        {currentItem.spec.afwerking}
                      </p>
                    )}
                    {currentItem.spec.steen && (
                      <p>
                        <span className='bold'>Steen:</span>{" "}
                        {currentItem.spec.steen}
                      </p>
                    )}
                    {currentItem.spec.lengteStaafje && (
                      <p>
                        <span className='bold'>Lengte Staafje:</span>{" "}
                        {currentItem.spec.lengteStaafje}
                      </p>
                    )}
                    {currentItem.spec.centraleEdelsteen && (
                      <p>
                        <span className='bold'>Centrale Edelsteen:</span>{" "}
                        {currentItem.spec.centraleEdelsteen}
                      </p>
                    )}
                    {currentItem.spec.edelstenen && (
                      <p>
                        <span className='bold'>Edelstenen:</span>{" "}
                        {currentItem.spec.edelstenen}
                      </p>
                    )}
                    {currentItem.spec.beschrijving && (
                      <p>{currentItem.spec.beschrijving}</p>
                    )}
                    {currentItem.spec.levertermijn && (
                      <p>
                        <span className='bold'>Levertermijn:</span>{" "}
                        {currentItem.spec.levertermijn}
                      </p>
                    )}
                    {currentItem.spec.opmerking && (
                      <p
                        style={{
                          marginTop: "20px",
                          color: "rgb(150,150,150)",
                          fontStyle: "italic",
                        }}
                      >
                        {currentItem.spec.opmerking}
                      </p>
                    )}
                    {currentItem.spec.footer && (
                      <p
                        style={{
                          marginTop: "15px",
                          color: "rgb(150,150,150)",
                          fontStyle: "italic",
                        }}
                      >
                        {currentItem.spec.footer}
                      </p>
                    )}
                  </div>
                </div>
                <Button onClick={addToCart} className='add-to-cart-btn'>
                  Toevoegen aan winkelmandje
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RaeShopItem;
