import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { initializeAnalytics, getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// initialize firebase
const firebaseApp = initializeApp(firebaseConfig);

// initialize localDb service
const db = getFirestore(firebaseApp);

// initialize firebase storage service
const storage = getStorage(firebaseApp);

// initialize firebase auth service
const auth = getAuth(firebaseApp);

// auth provider
const provider = new GoogleAuthProvider();

// initialize firebase analytics
let analytics;
setTimeout(() => {
  try {
    analytics = getAnalytics(firebaseApp);
  } catch (error) {
    console.log(error);
  }
}, 1000);

export { db, auth, provider, storage, analytics };
