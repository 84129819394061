import React from "react";
import { auth } from "../firebase/config";
import { signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import "./FacebookSignInButton.css";
import FacebookLogo from "../assets/FacebookLogo.png";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

const FacebookSignInButton = ({ loginAttempt, setLoginAttempt }) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
      if (user) {
        console.log(`${user.email} is already signed in using Facebook.`);
        navigate(`/jewelrydesigner`);
      } else {
        const result = await signInWithPopup(auth, provider);
        console.log(`${result.user.email} logged in successfully`);
        setLoginAttempt(loginAttempt + 1);
        navigate(`/jewelrydesigner`, { replace: true });
        console.log("New user authorized with Facebook.");
        console.log("User:", result.user);
        console.log("forcing page reload");
        window.location.reload();
      }
      // User is signed in using Facebook.
    } catch (error) {
      // Handle authentication error.
      console.error("Facebook login error:", error);
    }
  };

  return (
    <div className='facebookSignInButton' onClick={() => signInWithFacebook()}>
      <span className='icon'>
        <img src={FacebookLogo} />
      </span>
      <span className='buttonText'>Login met Facebook</span>
    </div>
  );
};

export default FacebookSignInButton;
