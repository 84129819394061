import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./RaeOneOfAKind.css";

// import foto assets
import werkbank from "../assets/werkbank.jpg";

export default function RaeOneOfAKind({
  oneOfAKindActive,
  setOneOfAKindActive,
}) {
  return (
    <>
      {oneOfAKindActive && (
        <div className='sideModal RaeOneOfAKind'>
          <button
            onClick={() => setOneOfAKindActive(false)}
            className='itemModal-closeButton'
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className='RaeOneOfAKind-content'>
            <h1>
              <span style={{ fontWeight: "300" }}></span>{" "}
              <span style={{ fontWeight: "800" }}>one-of-a-kind</span>
            </h1>
            <p>
              Op zoek naar een tastbare herinnering aan een moment dat je leven
              kleur geeft? Contacteer ons via{" "}
              <a
                href='mailto:hello@rae.gold'
                style={{
                  textDecoration: "none",
                  color: "rgba(237,190,174, 1)",
                  fontWeight: "800",
                }}
              >
                hello@rae.gold
              </a>{" "}
              om samen een perfect juweel uit te werken op jouw maat.
            </p>
            <p>
              Ik kijk ernaar uit om jouw verhaal met liefde en zorg te vertalen
              naar een juweel om te koesteren.
            </p>
            <img src={werkbank} className='RaeOneOfAKind-werkbank' />
          </div>
        </div>
      )}
    </>
  );
}
