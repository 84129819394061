import "./PaymentSucceeded.css";
import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem } from "@fortawesome/free-solid-svg-icons";
import { SnowshoeingOutlined } from "@mui/icons-material";

function PaymentSucceeded() {
  useEffect(() => {
    // clear items in local storage cart when payment successful
    localStorage.clear();
    console.log("Payment successful. Items cleared out of local storage");
  });
  return (
    <Box className='PaymentSucceeded'>
      <div className='gemIcon'>
        <FontAwesomeIcon icon={faGem} />
      </div>
      <div className='successText'>
        <h2>
          Bedankt voor je bestelling.
          <br />
          We gaan meteen aan de slag.
        </h2>
      </div>
      <Link href='./' style={{ textDecoration: "none" }}>
        <Button variant='outlined'>Back to homepage</Button>
      </Link>
    </Box>
  );
}

export default PaymentSucceeded;
