import React from "react";

function EmptyStroke() {
  return (
    <div
      style={{
        height: "10vh",
        background: "rgb(250,225,216)",
        position: "relative",
        top: "-20px",
      }}
    ></div>
  );
}

export default EmptyStroke;
