// app.js
import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import Website from "./pages/Website.js";
import PaymentSucceeded from "./pages/PaymentSucceeded.js";
import { Navigate } from "react-router-dom";

// Import rae collection data
import raeCollectionData from "./raeCollectionData.js";
import JewelryDesignerForm from "./pages/JewelryDesignerPage.js";
import DesignerLoginPage from "./pages/DesignerLoginPage.js";
import RaeRegisterPage from "./pages/RaeRegisterPage.js";
import RaeConfirmEmailPage from "./pages/RaeConfirmEmailPage.js";

// to assure private parts of your app
import { useAuthContext } from "./hooks/useAuthContext";

function PrivateRoute({ children }) {
  const { user } = useAuthContext();
  if (user) {
    if (!user.email) {
      return <Navigate to='/login' />;
    }
    if (user.providerData[0].providerId === "facebook.com") {
      return children;
    }
    if (user.emailVerified) {
      return children;
    }
    if (!user.emailVerified) {
      return <Navigate to='/confirmemail' />;
    }
  }
  return <Navigate to='/login' />;
}

function RedirectAfterConfirmation({ children }) {
  const { user } = useAuthContext();
  if (user) {
    if (user.emailVerified) {
      return <Navigate to='/login' />;
    } else {
      return children;
    }
  }
}

function App() {
  // set state of all items in the shop
  const { user, authIsReady } = useAuthContext();
  const [raeCollectionItems, setRaeCollectionItems] =
    useState(raeCollectionData);
  const [chosenStaafjeOfHoop, setChosenStaafjeOfHoop] = useState("");
  // set state of cart
  const [cart, setCart] = useState([]);
  const handleAddToCart = (
    item,
    chosenVersion,
    chosenSize,
    chosenQuantity,
    chosenStaafjeOfHoop
  ) => {
    const cartItem = {
      itemId: item.itemId,
      image: item.afbeelding[0],
      name: item.naam,
      itemPrice: chosenVersion.prijs,
      itemPriceStripeId: chosenVersion.prijsStripeId,
      material: chosenVersion.materiaal,
      chosenStaafjeOfHoop: chosenStaafjeOfHoop,
      size: chosenSize,
      quantity: chosenQuantity,
      totalPrice: chosenVersion.prijs * chosenQuantity,
    };
    setCart([...cart, cartItem]);
    console.log(cartItem);
  };

  const handleDeleteFromCart = (index) => {
    const newCart = cart.filter((item, i) => i !== index);
    setCart(newCart);
  };

  useEffect(() => {
    console.log("user has been updated");
    if (user) {
      console.log(`user email is verified: ${user.emailVerified}`);
    }
  }, [user]);

  return (
    <div className='App'>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route
              path='/'
              element={
                <Website
                  raeCollectionItems={raeCollectionItems}
                  cart={cart}
                  handleAddToCart={handleAddToCart}
                  handleDeleteFromCart={handleDeleteFromCart}
                />
              }
            />
            <Route path='/success' element={<PaymentSucceeded />} />

            <Route
              path='/jewelrydesigner'
              element={
                <PrivateRoute>
                  <JewelryDesignerForm user={user} />
                </PrivateRoute>
              }
            />

            <Route path='/login' element={<DesignerLoginPage />} />
            <Route path='/register' element={<RaeRegisterPage />} />

            <Route
              path='/confirmemail'
              element={
                <RedirectAfterConfirmation>
                  <RaeConfirmEmailPage user={user} />
                </RedirectAfterConfirmation>
              }
            />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
