import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import "./NavbarWebTop.css";
// import logo from "../assets/logo_MonthlyScend_Black.svg";
import logoRae from "../assets/LogoRae.png";
import shoppingBag from "../assets/shopping-bag.png";
import shoppingBagWhite from "../assets/shopping-bag-white.png";
import RaeShoppingCart from "./RaeShoppingCart.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";

const NavbarWebTop = ({
  cart,
  cartCount,
  handleDeleteFromCart,
  toggleNavMenu,
  setNavMenuActive,
  navMenuActive,
  toggleCartModal,
  setCartModalActive,
  cartModalActive,
  setShopItemModalActive,
  overOnsActive,
  setOverOnsActive,
  oneOfAKindActive,
  setOneOfAKindActive,
}) => {
  useEffect(() => {
    setNavMenuActive(cartModalActive);
  }, [cartModalActive]);

  const handleOverOnsClick = () => {
    setOneOfAKindActive(false);
    setOverOnsActive(!overOnsActive);
  };

  return (
    <div className={navMenuActive ? "NavbarWebTop" : "NavbarWebTop inactive"}>
      <div
        className={
          navMenuActive ? "NavbarWebTop-logo white" : "NavbarWebTop-logo"
        }
      >
        rae
      </div>
      <div
        className={
          navMenuActive
            ? "NavbarWebTop-menuToggle white"
            : "NavbarWebTop-menuToggle"
        }
        onClick={toggleNavMenu}
      >
        <FontAwesomeIcon
          className={navMenuActive ? "toggleBars white" : "toggleBars"}
          icon={navMenuActive ? faX : faBars}
        />
      </div>
      <div
        className={
          navMenuActive ? "NavbarWebTop-links" : "NavbarWebTop-links inactive"
        }
      >
        <Link
          className='NavbarWebTop-links-link'
          onClick={() => setNavMenuActive(false)}
          to='raeCollection'
        >
          onze collectie
        </Link>
        <Link
          className='NavbarWebTop-links-link'
          onClick={() => {
            setOneOfAKindActive(!oneOfAKindActive);
            setOverOnsActive(false);
          }}
        >
          one-of-a-kind
        </Link>
        <Link className='NavbarWebTop-links-link' onClick={handleOverOnsClick}>
          over rae
        </Link>
      </div>
      <div
        className={
          navMenuActive ? "NavbarWebTop-cart" : "NavbarWebTop-cart inactive"
        }
      >
        {!navMenuActive && (
          <RaeShoppingCart
            cart={cart}
            cartCount={cartCount}
            handleDeleteFromCart={handleDeleteFromCart}
            toggleCartModal={toggleCartModal}
            cartModalActive={cartModalActive}
            setCartModalActive={setCartModalActive}
            setNavMenuActive={setNavMenuActive}
            setShopItemModalActive={setShopItemModalActive}
          />
        )}
        {navMenuActive && (
          <RaeShoppingCart
            cart={cart}
            cartCount={cartCount}
            handleDeleteFromCart={handleDeleteFromCart}
            cartModalActive={cartModalActive}
            setCartModalActive={setCartModalActive}
            setNavMenuActive={setNavMenuActive}
            setShopItemModalActive={setShopItemModalActive}
            toggleCartModal={toggleCartModal}
          />
        )}
      </div>
    </div>
  );
};

export default NavbarWebTop;
