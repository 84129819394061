import React, { useEffect, useState } from "react";
import "./DesignerLoginPage.css";

// Import custom components
import GoogleSignInButton from "../components/GoogleSignInButton";
import FacebookSignInButton from "../components/FacebookSignInButton";
import AppleSignInButton from "../components/AppleSignInButton";

// import firebase functions
import { useAuthContext } from "../hooks/useAuthContext";

// import hooks
import { useNavigate } from "react-router-dom";

// Bootstrap components
import Spinner from "react-bootstrap/Spinner";

// import images
import DesignerLogo from "../assets/LogoRaeLogin.png";
import LoginBackground from "../assets/LoginBackground.png";

// import custom hooks
import { useLogin } from "../hooks/useLogin";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useResetPassword } from "../hooks/useResetPassword";

export default function DesignerLoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, authIsReady } = useAuthContext();
  const navigate = useNavigate();

  // initiate custom hooks
  const { login, error } = useLogin();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginAttempt, setLoginAttempt] = useState(0);
  const { resetPassword, notification, error: resetError } = useResetPassword();
  const { height, width } = useWindowDimensions();

  // event handle functions
  const handleUsernameChange = (event) => {
    setEmail(event.target.value.toLowerCase());
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    login(email, password);
  };

  const handleResetPassword = () => {
    if (email) {
      resetPassword(email);
    } else {
      console.log("Please enter your email to reset password.");
    }
  };

  useEffect(() => {
    if (user) {
      if (user.emailVerified) {
        console.log(
          "There's a user, who has an email which is verified.",
          `user: ${user}`
        );
        navigate(`/jewelrydesigner`);
      } else if (!user.email) {
        console.log("There's a user but no email");
      } else if (user.email && !user.emailVerified) {
        console.log("There's a user email but it's not verified yet.");
      }
    } else {
      console.log("No user.");
    }
  }, [user]);

  useEffect(() => {
    console.log("checking user credentials...");
    setTimeout(() => {
      if (authIsReady && user) {
        if (user.emailVerified) {
          console.log(`${user.email} is logged in and email is verified.`);
          navigate(`/jewelrydesigner`);
        }
      } else if (authIsReady && !user) {
        console.log("authIsReady but user is not yet logged.");
        console.log(`This is login attempt number: ${loginAttempt}`);
      } else {
        console.log("authIsReady is not yet ready.");
      }
    }, 500);
  }, [user, authIsReady, loginAttempt]);

  // identify browser type
  const [browser, setBrowser] = useState("");
  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Firefox")) {
      setBrowser("Firefox");
    } else if (userAgent.includes("Chrome")) {
      setBrowser("Chrome");
    } else if (userAgent.includes("Safari")) {
      setBrowser("Safari");
    } // Add more conditions for other browsers as needed
  }, []);

  return (
    <div className='DesignerLoginPage'>
      <img
        className='DesignerLoginPage-background'
        src={LoginBackground}
        alt='LoginBackground'
      />
      <div className='DesignerLoginPage-loginCard'>
        <img
          className='DesignerLoginPage-logo'
          src={DesignerLogo}
          alt='DesignerLogo'
        />
        <div className='DesignerLoginPage-loginCard-body'>
          <form>
            <label htmlFor='email'>Email</label>
            <input
              type='text'
              id='email'
              name='email'
              placeholder='email'
              value={email}
              onChange={handleUsernameChange}
            />
            <label htmlFor='password'>Paswoord</label>
            <input
              type='password'
              id='password'
              name='password'
              placeholder='password'
              value={password}
              onChange={handlePasswordChange}
            />
            <p className='forgotPassword' onClick={handleResetPassword}>
              Paswoord vergeten?
            </p>
            <button
              className='submitButton'
              type='submit'
              onClick={handleSubmit}
            >
              {!error && isSubmitting && (
                <Spinner
                  className='loadingSpinner'
                  animation='border'
                  size='sm'
                />
              )}{" "}
              Login
            </button>
            <div>{/* ... other JSX elements */}</div>
          </form>
          {/* Display notification below the form */}
          {notification && (
            <p className='notificationMessage'>{notification}</p>
          )}
          {/* If you want to display errors separately, add this */}
          {resetError && <p className='errorMessage'>{resetError}</p>}
          {/* ... other components */}
          <div className='DesignerLoginPage-loginCard-footer'>
            <p
              style={{
                color: "rgb(247, 168, 0)",
                width: "80%",
                margin: "0px auto",
              }}
            >
              {error}
            </p>
            <p>
              {!error && (
                <span>
                  Nog geen account bij Rae?
                  <br />
                </span>
              )}
              {error && (
                <>
                  {!error.includes("verify") && (
                    <span>
                      Registreer <a href='/register'>hier</a>
                    </span>
                  )}
                </>
              )}
              {!error && (
                <>
                  <span>
                    Registreer <a href='/register'>hier</a>
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
        {browser !== "Safari" && (
          <>
            <div
              className='DesignerLoginPage-loginCard-sso-logins'
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10px auto",
              }}
            >
              <div class='divider'>
                <div class='line'></div>
                <span class='text'>of</span>
                <div class='line'></div>
              </div>
            </div>
            <div className='DesignerLoginPage-loginCard-body'>
              <GoogleSignInButton
                loginAttempt={loginAttempt}
                setLoginAttempt={setLoginAttempt}
              />
              <FacebookSignInButton
                loginAttempt={loginAttempt}
                setLoginAttempt={setLoginAttempt}
              />
              {/* Display notification below the form */}
              {notification && (
                <p className='notificationMessage'>{notification}</p>
              )}
              {/* If you want to display errors separately, add this */}
              {resetError && <p className='errorMessage'>{resetError}</p>}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
