import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import RaeShopItem from "../components/RaeShopItem";

import "bootstrap/dist/css/bootstrap.min.css";
import "./RaeCollection.css";

function RaeCollection({
  raeCollectionItems,
  handleAddToCart,
  setNavMenuActive,
  setCartModalActive,
  setShopItemModalActive,
  shopItemModalActive,
}) {
  const itemFilters = [
    "alles",
    "oorbellen",
    "ketting",
    "armband",
    "ring",
    "cadeaubon",
  ];
  const materialFilters = ["zie prijzen"];

  const [activeItemFilter, setActiveItemFilter] = useState(null);
  const [activeMaterialFilter, setActiveMaterialFilter] = useState(false);
  const navigate = useNavigate(); // Replace `const history = useHistory();` with this line.

  const filteredItems = raeCollectionItems.filter(
    (item) =>
      activeItemFilter === null ||
      activeItemFilter === "alles" ||
      item.soort === activeItemFilter
  );

  useEffect(() => {
    const hash = window.location.hash;
    if (hash && hash === "#raeCollection") {
      const element = document.getElementById("raeCollection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div className='RaeCollection' id='raeCollection'>
      <h1 className='RaeCollection-title'>
        onze <span className='RaeCollection-title-bold'>collectie</span>
      </h1>
      <div className='RaeCollection-title-filterItemRow'>
        <FilterItems
          items={itemFilters}
          baseId='RaeCollection-title-filterItemRow-item'
          activeFilter={activeItemFilter}
          onClick={setActiveItemFilter}
        />
      </div>
      <div className='RaeCollection-itemContainer'>
        {filteredItems.map((item) => (
          <RaeShopItem
            key={item.itemId}
            shopItem={item}
            setNavMenuActive={setNavMenuActive}
            setCartModalActive={setCartModalActive}
            handleAddToCart={handleAddToCart}
            setShopItemModalActive={setShopItemModalActive}
            shopItemModalActive={shopItemModalActive}
          />
        ))}
      </div>
    </div>
  );
}

export default RaeCollection;

function FilterItems({ items, baseId, activeFilter, onClick }) {
  return (
    <>
      <div className={`${baseId}-filters desktop`}>
        {items.map((item) => (
          <div
            key={item}
            id={`${baseId}-filters-${item}`}
            className={item === activeFilter ? "active-filter" : ""}
            onClick={() => onClick(item)}
          >
            {item}
          </div>
        ))}
      </div>
      <Form.Select
        className={`${baseId}-filters mobile`}
        value={activeFilter || ""}
        onChange={(e) => onClick(e.target.value)}
      >
        {items.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </Form.Select>
    </>
  );
}
