import React, { useState } from "react";

// import hooks
import { useNavigate } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";

// Import styles
import "./RaeJewelryDesignerToolMenu.css";

// Import assets
import LogoRae from "../assets/LogoRae.png";

// Import icon components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUser,
  faHeart,
  faPenRuler,
  faTimes,
  faRing,
  faGem,
  faDashboard,
  faBasketShopping,
  faShop,
  faLocationPin,
  faEarthEurope,
  faCheese,
  faFlask,
  faQuestion,
  faHatWizard,
  faBullseye,
  faRankingStar,
  faGear,
  faTools,
  faPen,
  faRightFromBracket,
  faRobot,
  faHome,
  faBinoculars,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";

// Menu items
const menuItems = [
  {
    id: "Juweel Ontwerper",
    icon: faPenRuler,
    text: "Juweel Ontwerper",
  },
  {
    id: "Jouw Ontwerpen",
    icon: faRing,
    text: "Jouw Ontwerpen",
  },
  {
    id: "Andere AI Ontwerpen",
    icon: faGem,
    text: "Andere AI Ontwerpen",
  },
  {
    id: "Jouw Selecties",
    icon: faHeart,
    text: "Jouw Selecties",
  },
];

// Reusable components

const MenuItem = ({
  id,
  visibleContent,
  icon,
  text,
  textSize,
  marginLeft,
  handleMenuClick,
}) => (
  <a id={id} onClick={handleMenuClick}>
    <div
      className={visibleContent === id ? "active" : ""}
      style={{ margin: "0px" }}
    >
      <span className='RaeJewelryDesignerTool-menu-items-item-icon'>
        {icon && <FontAwesomeIcon icon={icon} />}
      </span>
      <span
        className='RaeJewelryDesignerTool-menu-items-item-text'
        style={{
          marginLeft: marginLeft,
          fontSize: textSize,
        }}
      >
        {text}
      </span>
    </div>
  </a>
);

const MenuCloseButton = ({ setMenuActive }) => (
  <button className='MenuCloseButton' onClick={() => setMenuActive(false)}>
    <FontAwesomeIcon icon={faTimes} />
  </button>
);

/* Start main component -----------------------------------------------------------------------------------*/
/* ---------------------------------------------------------------------------------------------------------*/
/* ---------------------------------------------------------------------------------------------------------*/

export default function RaeJewelryDesignerToolMenu({
  handleMenuClick,
  visibleContent,
  setVisibleContent,
  menuActive,
  setMenuActive,
  yourSelectionBuckets,
  mobileDevice,
}) {
  const navigate = useNavigate();
  const { logout } = useLogout();
  const handleSignOut = () => {
    console.log("signing out");
    logout();
  };
  return (
    <>
      {!menuActive && (
        <div>
          <FontAwesomeIcon
            icon={faBars}
            className='menuItem menuInActive'
            onClick={() => setMenuActive(true)}
            style={{
              marginTop: mobileDevice ? "-10px" : "0px",
              marginLeft: mobileDevice ? "0px" : "20px",
            }}
          />
        </div>
      )}
      {menuActive && (
        <div className='RaeJewelryDesignerTool-menu'>
          <div className='RaeJewelryDesignerTool-menu-header'>
            <MenuCloseButton setMenuActive={setMenuActive} />
            <img className='logo' alt='logo of Rae' src={LogoRae} />
          </div>
          <div className='RaeJewelryDesignerTool-menu-items'>
            <div className='RaeJewelryDesignerTool-menu-items-titleHolder'>
              <h3
                style={{
                  color: "rgba(50,50,50)",
                  fontSize: "18px",
                  fontWeight: "800",
                }}
              >
                Menu
              </h3>
            </div>
            {menuItems.map((item) => (
              <MenuItem
                key={item.id}
                id={item.id}
                visibleContent={visibleContent}
                setVisibleContent={setVisibleContent}
                handleMenuClick={handleMenuClick}
                icon={item.icon}
                text={item.text}
              />
            ))}
            {yourSelectionBuckets.map((selectionBucket, index) => (
              <MenuItem
                key={selectionBucket.selectionOrder}
                id={selectionBucket.id}
                visibleContent={visibleContent}
                handleMenuClick={handleMenuClick}
                text={selectionBucket.name}
                textSize={"14px"}
                marginLeft={"20px"}
                marginBottom={"10px"}
              />
            ))}
          </div>
          <hr
            style={{
              borderColor: "rgba(255,255,255,0.1)",
              width: "100%",
              marginTop: "50px",
            }}
          />

          <div className='RaeJewelryDesignerTool-menu-logout'>
            <button className='BackToRaeButton' onClick={() => navigate(`/`)}>
              Teurg naar rae website
            </button>
            <button className='LogoutButton' onClick={() => logout()}>
              <FontAwesomeIcon icon={faRightFromBracket} />
              <span style={{ marginLeft: "10px" }}>Logout</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
