import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Grid";

const Footer = ({
  setTermsAndConditionsActive,
  termsAndConditionsActive,
  setOverOnsActive,
  overOnsActive,
}) => {
  return (
    <Box className='Footer' id='footer'>
      <footer className='site-footer'>
        <Grid container>
          <Grid container>
            <Grid xs={12} md={6} className='Footer-aboutText'>
              <h6>rae.gent</h6>
              <p className='text-justify'>
                {" "}
                Volg ons zeker op instagram{" "}
                <a
                  href='https://www.instagram.com/rae.gent/'
                  target='_blank'
                  className='Footer-Link-Instagram'
                  style={{
                    textDecoration: "none",
                    color: "rgba(217,170,154, 1)",
                    fontWeight: "800",
                  }}
                >
                  @rae.gent
                </a>{" "}
                om op de hoogte te blijven van onze collectie.
              </p>
            </Grid>

            <Grid xs={6} md={3}>
              <span
                onClick={() =>
                  setTermsAndConditionsActive(!termsAndConditionsActive)
                }
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontSize: "12px",
                }}
              >
                Verkoopsvoorwaarden
              </span>
              <br />
              <span
                onClick={() => setOverOnsActive(!overOnsActive)}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontSize: "12px",
                }}
              >
                Over Rae
              </span>
            </Grid>
          </Grid>
          <hr />
        </Grid>
        <div>
          <Grid container xs={12}>
            <Grid xs={8} className='FooterBottomLine'>
              <p className='copyright-text'>Copyright &copy; 2023</p>
            </Grid>

            <Grid xs={4}>
              <ul className='social-icons'>
                <li>
                  <a
                    className='Instagram'
                    href='https://www.instagram.com/rae.gent/'
                    target='_blank'
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li>
                  <a className='Instagram' href='mailto:hello@rae.gold'>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </footer>
    </Box>
  );
};

export default Footer;
