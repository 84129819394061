import * as React from "react";
import { useState, useEffect } from "react";
import RaeCollection from "./RaeCollection.js";
import Footer from "../components/Footer.js";
import LandingPage from "./LandingPage.js";
import NavbarWebTop from "../components/NavbarWebTop.js";
import EmptyStroke from "./EmptyStroke";
import RaeTermsAndConditions from "../components/RaeTermsAndConditions.js";
import RaeOverOns from "../components/RaeOverOns.js";
import RaeOneOfAKind from "../components/RaeOneOfAKind.js";

function Website({
  raeCollectionItems,
  cart,
  cartCount,
  handleDeleteFromCart,
  handleAddToCart,
}) {
  const [navMenuActive, setNavMenuActive] = useState(false);
  const toggleNavMenu = () => {
    setNavMenuActive(!navMenuActive);
  };

  const [cartModalActive, setCartModalActive] = useState(false);
  const toggleCartModal = () => {
    setCartModalActive(!cartModalActive);
    setNavMenuActive(false);
    setShopItemModalActive(false);
  };

  const [shopItemModalActive, setShopItemModalActive] = useState(null);

  const [termsAndConditionsActive, setTermsAndConditionsActive] =
    useState(false);

  const [overOnsActive, setOverOnsActive] = useState(false);

  const [oneOfAKindActive, setOneOfAKindActive] = useState(false);

  return (
    <div className='Website'>
      <NavbarWebTop
        cart={cart}
        cartCount={cartCount}
        handleDeleteFromCart={handleDeleteFromCart}
        toggleNavMenu={toggleNavMenu}
        setNavMenuActive={setNavMenuActive}
        navMenuActive={navMenuActive}
        toggleCartModal={toggleCartModal}
        setCartModalActive={setCartModalActive}
        cartModalActive={cartModalActive}
        setShopItemModalActive={setShopItemModalActive}
        overOnsActive={overOnsActive}
        setOverOnsActive={setOverOnsActive}
        oneOfAKindActive={oneOfAKindActive}
        setOneOfAKindActive={setOneOfAKindActive}
      />
      <LandingPage
        setOverOnsActive={setOverOnsActive}
        oneOfAKindActive={oneOfAKindActive}
        setOneOfAKindActive={setOneOfAKindActive}
      />
      <EmptyStroke />
      <RaeCollection
        raeCollectionItems={raeCollectionItems}
        handleAddToCart={handleAddToCart}
        setNavMenuActive={setNavMenuActive}
        setCartModalActive={setCartModalActive}
        setShopItemModalActive={setShopItemModalActive}
        shopItemModalActive={shopItemModalActive}
      />
      <RaeTermsAndConditions
        termsAndConditionsActive={termsAndConditionsActive}
        setTermsAndConditionsActive={setTermsAndConditionsActive}
      />
      <RaeOverOns
        overOnsActive={overOnsActive}
        setOverOnsActive={setOverOnsActive}
      />
      <RaeOneOfAKind
        oneOfAKindActive={oneOfAKindActive}
        setOneOfAKindActive={setOneOfAKindActive}
      />
      {/*<OurStory />
      <Shop addItemToCheckoutCounter={addItemToCheckoutCounter} />*/}
      <Footer
        setTermsAndConditionsActive={setTermsAndConditionsActive}
        termsAndConditionsActive={termsAndConditionsActive}
        setOverOnsActive={setOverOnsActive}
        overOnsActive={overOnsActive}
      />
    </div>
  );
}

export default Website;
