import React, { useEffect, useState, useRef } from "react";
import RaeLogo from "../assets/LogoRaeLogin.png";
import { useSignup } from "../hooks/useSignup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faPaperPlane,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase/config";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useAuthContext } from "../hooks/useAuthContext";

// import bootstrap components
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

// import styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./RaeRegisterPage.css";

export default function RaeConfirmEmailPage({ user }) {
  return (
    <div className='RaeRegisterPage'>
      {!user.emailVerified && (
        <div className='RaeRegisterPage-registerCard-registeredButEmailNotYerVerrified'>
          <FontAwesomeIcon
            icon={faLink}
            style={{ fontSize: "60px", marginBottom: "40px" }}
          />
          <p>
            Je email is nog niet geverifieerd.
            <br />
            Ga naar je inbox en klik op onze toegestuurde link.
            <br />
            <br />
            (indien je geen link hebt ontvangen, bekijk dan zeker je spam
            folder)
          </p>
        </div>
      )}
      {user.emailVerified && (
        <div className='RaeRegisterPage-registerCard-registeredButEmailNotYerVerrified'>
          <FontAwesomeIcon
            icon={faLink}
            style={{ fontSize: "60px", marginBottom: "40px" }}
          />
          <p>Super, je email is geverifieerd!</p>
          <Button
            style={{ background: "rgba(41,41,51)", marginTop: "20px" }}
            href='/jewelryDesigner'
          >
            Login
          </Button>
        </div>
      )}
    </div>
  );
}
