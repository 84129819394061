import React, { useEffect, useState } from "react";
import MasonryLayout from "../components/MasonryLayout";
import Dropdown from "../components/Dropdown";
import "./JewelryDesignerPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlus,
  faMinus,
  faHeart,
  faCheck,
  faTrash,
  faQuestion,
  faSave,
  faArrowLeft,
  faWindowClose,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "react-bootstrap/Spinner";
import { db, storage } from "../firebase/config";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import JewelryDesignForm from "../components/JewelryDesignForm";
import RaeJewelryDesignerToolMenu from "../components/RaeJewelryDesignerToolMenu";
// import hooks
import { useNavigate } from "react-router-dom";

const JewelryDesignerForm = ({ user }) => {
  const [yourGeneratedImages, setYourGeneratedImages] = useState([]);
  const [yourSelection, setYourSelection] = useState([]);
  const [otherAIGeneratedImages, setOtherAIGeneratedImages] = useState([]);
  const [yourSelectionBuckets, setYourSelectionBuckets] = useState([]);
  const [selectedJewels, setSelectedJewels] = useState([]);
  const [imageEditModes, setImageEditModes] = useState(
    Array(yourGeneratedImages.length).fill(false)
  );
  const [selectedView, setSelectedView] = useState("Juweel Ontwerper");
  const [profileMenuActive, setProfileMenuActive] = useState(false);
  const [imageCreating, setImageCreating] = useState(false);
  const [imageSelectionActiveIndex, setImageSelectionActiveIndex] =
    useState(null);

  const [menuActive, setMenuActive] = useState(false);
  const [bucketTitleNameInEditMode, setBucketTitleNameInEditMode] =
    useState(null);
  // Determine if the user is on a mobile device
  const [mobileDevice, setMobileDevice] = useState(window.innerWidth < 800);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setMobileDevice(window.innerWidth < 800);
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth < 800) {
      setMenuActive(false);
    } else {
      setMenuActive(true);
    }
  }, [mobileDevice]);

  // fetching all undeleted images generated by the user
  useEffect(() => {
    const fetchYourGeneratedImages = async () => {
      const q = query(
        collection(db, "generatedImages"),
        where("creator", "==", user.email),
        where("imageDeleted", "==", false)
      );

      if (q) {
        try {
          const querySnapshot = await getDocs(q);
          const images = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => {
              // Convert Firebase Timestamps to milliseconds and compare
              const dateA = a.generationDate.seconds * 1000;
              const dateB = b.generationDate.seconds * 1000;
              return dateB - dateA; // Sort in descending order
            });

          setYourGeneratedImages(images);
        } catch (error) {
          console.error("Error fetching generated images: ", error);
        }
      }
    };

    fetchYourGeneratedImages();
  }, [user.email, yourGeneratedImages.length]);

  // fetch all Other AI generated images
  useEffect(() => {
    const fetchOtherAIGeneratedImages = async () => {
      const q = query(
        collection(db, "generatedImages"),
        where("selectedForGallery", "==", true)
      );
      if (q) {
        try {
          const querySnapshot = await getDocs(q);
          const images = querySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => {
              // Convert Firebase Timestamps to milliseconds and compare
              const dateA = a.generationDate.seconds * 1000;
              const dateB = b.generationDate.seconds * 1000;
              return dateB - dateA; // Sort in descending order
            });
          setOtherAIGeneratedImages(images);
        } catch (error) {
          console.error("Error fetching generated images: ", error);
        }
      }
    };

    fetchOtherAIGeneratedImages();
  }, [user.email]);

  // fetch all selection buckets for the user
  const [imagesAddedToSelection, setImagesAddedToSelection] = useState(0);
  useEffect(() => {
    const fetchYourSelectionBuckets = async () => {
      const selectionBucketsRef = collection(db, "selectionBuckets");
      const q = query(selectionBucketsRef, where("creator", "==", user.email));
      try {
        const querySnapshot = await getDocs(q);
        const buckets = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const orderedBuckets = buckets.sort(
          (a, b) => a.selectionOrder - b.selectionOrder
        );
        setYourSelectionBuckets(orderedBuckets);
      } catch (error) {
        console.error("Error fetching generated images: ", error);
      }
    };
  }, []);

  // fetch all selection buckets for the user AND make 3 collections if no selection buckets yet

  useEffect(() => {
    const fetchYourSelectionBuckets = async () => {
      const selectionBucketsRef = collection(db, "selectionBuckets");
      const q = query(selectionBucketsRef, where("creator", "==", user.email));

      try {
        let querySnapshot = await getDocs(q);
        let buckets = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (buckets.length === 0) {
          // Create buckets if they don't exist
          for (let i = 1; i <= 3; i++) {
            await addDoc(selectionBucketsRef, {
              creator: user.email,
              name: `Selectie ${i}`,
              selectionOrder: i,
              images: [],
              id: `Selection${i}`,
              finalDesignRequested: false,
            });
          }

          // Refetch buckets after creation
          querySnapshot = await getDocs(q);
          buckets = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Identify and delete duplicates => workaround as somehow firebase created duplicates during intial creation
          const uniqueBucketNames = new Set();
          const bucketsToDelete = [];

          buckets.forEach((bucket) => {
            if (uniqueBucketNames.has(bucket.name)) {
              bucketsToDelete.push(bucket.id);
            } else {
              uniqueBucketNames.add(bucket.name);
            }
          });

          const deletePromises = bucketsToDelete.map((bucketId) =>
            deleteDoc(doc(db, "selectionBuckets", bucketId))
          );
          await Promise.all(deletePromises);

          // Fetch the remaining buckets
          querySnapshot = await getDocs(q);
          buckets = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        }
        const orderedBuckets = buckets.sort(
          (a, b) => a.selectionOrder - b.selectionOrder
        );
        setYourSelectionBuckets(orderedBuckets);
      } catch (error) {
        console.error("Error fetching generated images: ", error);
      }
    };

    fetchYourSelectionBuckets();
  }, []); // Dependencies array is empty, so this runs once on mount

  useEffect(() => {
    console.log("Updated yourGeneratedImages", yourGeneratedImages);
  }, [yourGeneratedImages]);

  const [numberOfImagesCreatedThisWeek, setNumberOfImagesCreatedThisWeek] =
    useState(0);
  useEffect(() => {
    setNumberOfImagesCreatedThisWeek(
      getImagesCreatedThisWeek(yourGeneratedImages).length
    );
  }, [yourGeneratedImages]);

  function getImagesCreatedThisWeek(yourGeneratedImages) {
    // Get the current date and time
    const now = new Date();

    // Calculate the start of the week (assuming week starts on Sunday)
    const startOfWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - now.getDay()
    );

    // Filter images created this week
    return yourGeneratedImages.filter((image) => {
      // Check if creationDate is defined and has the property 'seconds'
      if (image.generationDate && image.generationDate.seconds !== undefined) {
        // Convert Firebase Timestamp to JavaScript Date object
        const creationDate = new Date(image.generationDate.seconds * 1000);

        // Check if the image was created on or after the start of this week
        return creationDate >= startOfWeek;
      }
      return false; // Exclude images without a valid creationDate
    });
  }

  const handleImageDelete = async (image) => {
    const selectionBucketsRef = collection(db, "generatedImages");
    const q = query(
      selectionBucketsRef,
      where("creator", "==", user.email),
      where("imageId", "==", image.imageId)
    );

    try {
      // Execute the query
      const querySnapshot = await getDocs(q);

      // Loop through the documents and update each one
      querySnapshot.forEach(async (doc) => {
        const docRef = doc.ref;
        await updateDoc(docRef, {
          imageDeleted: true,
        });
        console.log("Document updated successfully");
      });

      // Update the yourSelectionBuckets state
      setYourGeneratedImages((prevImages) => {
        return prevImages.map((imageToUpdate) => {
          if (imageToUpdate.imageId === image.imageId) {
            console.log("image to update", imageToUpdate);
            return { ...imageToUpdate, imageDeleted: true };
          }
          return imageToUpdate;
        });
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  // add image to the correct bucket
  const handleAddImageToSelectionButton = async (
    userName,
    selectionBucketName,
    image
  ) => {
    try {
      // Query to find the correct selection bucket
      const q = query(
        collection(db, "selectionBuckets"),
        where("creator", "==", userName),
        where("name", "==", selectionBucketName)
      );

      const querySnapshot = await getDocs(q);

      // Check if the bucket exists
      if (!querySnapshot.empty) {
        const bucketDoc = querySnapshot.docs[0]; // Assuming there's only one match

        // Retrieve current images and add the new one
        const currentImages = bucketDoc.data().images;
        const updatedImages = [...currentImages, image];

        // Update the bucket with the new image list
        await updateDoc(bucketDoc.ref, {
          images: updatedImages,
        });

        // Update the yourSelectionBuckets state
        setYourSelectionBuckets((prevBuckets) => {
          return prevBuckets.map((bucket) => {
            if (
              bucket.name === selectionBucketName &&
              bucket.creator === userName
            ) {
              return { ...bucket, images: updatedImages };
            }
            return bucket;
          });
        });
      } else {
        console.log(
          "Selection bucket not found or multiple buckets with the same name and creator."
        );
      }
    } catch (error) {
      console.error("Error adding image to selection bucket: ", error);
    }
  };

  // delete image from the correct bucket
  const handleRemoveImageFromSelectionButton = async (
    userName,
    selectionBucketName,
    image
  ) => {
    try {
      // Query to find the correct selection bucket
      console.log("username", userName);
      console.log("selectionBucketName", selectionBucketName);
      const q = query(
        collection(db, "selectionBuckets"),
        where("creator", "==", userName),
        where("name", "==", selectionBucketName)
      );

      const querySnapshot = await getDocs(q);

      // Check if the bucket exists
      if (!querySnapshot.empty) {
        const bucketDoc = querySnapshot.docs[0]; // Assuming there's only one match

        // Retrieve current images
        const currentImages = bucketDoc.data().images;
        console.log("currentimages", currentImages);

        // Remove the specified image
        const updatedImages = currentImages.filter(
          (img) => img.imageId !== image.imageId
        );
        console.log("updated images", updatedImages);

        // Update the bucket with the removed image
        await updateDoc(bucketDoc.ref, {
          images: updatedImages,
        });

        // Update the yourSelectionBuckets state
        setYourSelectionBuckets((prevBuckets) => {
          return prevBuckets.map((bucket) => {
            if (
              bucket.name === selectionBucketName &&
              bucket.creator === userName
            ) {
              return { ...bucket, images: updatedImages };
            }
            return bucket;
          });
        });
      } else {
        console.log(
          "Selection bucket not found or multiple buckets with the same name and creator."
        );
      }
    } catch (error) {
      console.error("Error removing image from selection bucket: ", error);
    }
  };

  const handlePlusClick = (imageId) => {
    console.log("plus clicked", imageId);
    if (imageSelectionActiveIndex === imageId) {
      setImageSelectionActiveIndex(null);
    } else setImageSelectionActiveIndex(imageId);
  };

  const [nameOfCurrentDeepDiveSelection, setNameOfCurrentDeepDiveSelection] =
    useState(null);
  const handleMenuClick = (e) => {
    setSelectedView(e.currentTarget.id);
    setNameOfCurrentDeepDiveSelection(null);
    setImageSelectionActiveIndex(null);
    if (mobileDevice) {
      setMenuActive(false);
    }
  };

  const [editedTitle, setEditedTitle] = useState(null);

  const handleEditBucketTitleToggleEditMode = (selectionBucket) => {
    setEditedTitle(selectionBucket.name);
    setBucketTitleNameInEditMode(selectionBucket.id);
  };

  const handleEditBucketTitleSave = async (selectionBucket) => {
    if (editedTitle) {
      const selectionBucketsRef = collection(db, "selectionBuckets");
      const q = query(
        selectionBucketsRef,
        where("creator", "==", user.email),
        where("id", "==", selectionBucket.id)
      );

      try {
        // Execute the query
        const querySnapshot = await getDocs(q);

        // Loop through the documents and update each one
        querySnapshot.forEach(async (doc) => {
          const docRef = doc.ref;
          await updateDoc(docRef, {
            name: editedTitle,
          });
          console.log("Document updated successfully");
        });

        // Update the yourSelectionBuckets state
        setYourSelectionBuckets((prevBuckets) => {
          return prevBuckets.map((bucket) => {
            if (bucket.id === selectionBucket.id) {
              return { ...bucket, name: editedTitle };
            }
            return bucket;
          });
        });
        setBucketTitleNameInEditMode(null);
        setEditedTitle(null);
      } catch (error) {
        setBucketTitleNameInEditMode(null);
        setEditedTitle(null);
        console.error("Error updating document: ", error);
      }
    }
  };

  const [cardInCommentEditMode, setCardInCommentEditMode] = useState(undefined);
  const [editedComment, setEditedComment] = useState(undefined);

  const handleToggleEditMode = (image, selectionBucket) => {
    setCardInCommentEditMode(image.imageId);
    setEditedComment(
      selectionBucket.images.find((obj) => obj.imageId === image.imageId)
        .comment
    );
  };

  const handleCommentSave = async (image, selectionBucket) => {
    // Reference to the collection
    const collRef = collection(db, "selectionBuckets");

    // Create a query against the collection
    const q = query(
      collRef,
      where("id", "==", selectionBucket.id),
      where("creator", "==", selectionBucket.creator)
    );

    try {
      // Execute the query
      const querySnapshot = await getDocs(q);
      let docRef;

      // Loop through the results
      querySnapshot.forEach((doc) => {
        // Assuming only one document will match, use the first one found
        docRef = doc.ref;
      });

      // If a matching document is found
      if (docRef) {
        // Get the current document
        const docSnap = await getDoc(docRef);

        // Document data
        const data = docSnap.data();

        // Find the image object and update its comment
        const updatedImages = data.images.map((imageToUpdate) => {
          if (imageToUpdate.imageId === image.imageId) {
            return { ...imageToUpdate, comment: editedComment };
          }
          return imageToUpdate;
        });

        // Update the document
        await updateDoc(docRef, {
          images: updatedImages,
        });

        // Update the local state (yourSelectionBuckets) with the updated comment
        const updatedSelectionBuckets = yourSelectionBuckets.map((bucket) => {
          if (
            bucket.id === selectionBucket.id &&
            bucket.creator === selectionBucket.creator
          ) {
            return { ...bucket, images: updatedImages };
          }
          return bucket;
        });

        setYourSelectionBuckets(updatedSelectionBuckets);

        console.log("Document successfully updated");
        setCardInCommentEditMode(undefined);
        setEditedComment(undefined);
      } else {
        console.error(
          "No document found with the specified selectionId and creator"
        );
        setCardInCommentEditMode(undefined);
      }
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleFinalSelectionBucketSubmit = async (selectionBucket) => {
    const selectionBucketsRef = collection(db, "selectionBuckets");
    const q = query(
      selectionBucketsRef,
      where("creator", "==", user.email),
      where("id", "==", selectionBucket.id)
    );

    try {
      // Execute the query
      const querySnapshot = await getDocs(q);

      // Loop through the documents and update each one
      querySnapshot.forEach(async (doc) => {
        const docRef = doc.ref;
        await updateDoc(docRef, {
          finalDesignRequested: true,
        });
        console.log("Document updated successfully");
      });

      // Update the yourSelectionBuckets state
      setYourSelectionBuckets((prevBuckets) => {
        return prevBuckets.map((bucket) => {
          if (bucket.id === selectionBucket.id) {
            return { ...bucket, finalDesignRequested: true };
          }
          return bucket;
        });
      });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const [promptIntoEditMode, setPromptIntoEditMode] = useState(undefined);
  const [editedPrompt, setEditedPrompt] = useState(undefined);

  useEffect(() => {
    if (yourGeneratedImages.length > 0) {
      setEditedPrompt(yourGeneratedImages[0].revised_prompt);
    }
  }, [yourGeneratedImages]);

  const handleSetPromptIntoEditMode = () => {
    setPromptIntoEditMode(true);
  };
  const handleEditPromptSave = () => {
    setPromptIntoEditMode(false);
  };

  return (
    <div
      className={
        menuActive ? "JewelryDesignerForm active" : "JewelryDesignerForm"
      }
    >
      <div className='pageTopNavigation'>
        <RaeJewelryDesignerToolMenu
          handleMenuClick={handleMenuClick}
          visibleContent={selectedView}
          setVisibleContent={setSelectedView}
          menuActive={menuActive}
          setMenuActive={setMenuActive}
          yourSelectionBuckets={yourSelectionBuckets}
          mobileDevice={mobileDevice}
        />
        <div className='aboutThisPageQuestionMark'>
          <FontAwesomeIcon icon={faQuestion} />
        </div>
      </div>
      {selectedView === "Jouw Profiel" && (
        <div
          style={{
            width: "100%",
            position: "relative",
            left: "5%",
            top: "0px",
          }}
        >
          <div className='pageContentContainer'>
            <h2 style={{ marginBottom: "40px" }}>Jouw Profiel</h2>
            <p>
              <b>Email:</b> {user.email}
            </p>
            <p>
              <b>Gegenereerde afbeeldingen totaal:</b>{" "}
              {yourGeneratedImages.length}
            </p>
            <p>
              <b>Gegenereerde afbeeldingen deze week:</b>{" "}
              {numberOfImagesCreatedThisWeek}/10
            </p>
          </div>
        </div>
      )}
      <div className='contentRow'>
        {selectedView === "Juweel Ontwerper" && (
          <div className='imageGenerationSettings'>
            <JewelryDesignForm
              user={user}
              yourGeneratedImages={yourGeneratedImages}
              setYourGeneratedImages={setYourGeneratedImages}
              imageCreating={imageCreating}
              setImageCreating={setImageCreating}
              setSelectedView={setSelectedView}
            />
          </div>
        )}
        <div
          className={
            selectedView === "Juweel Ontwerper"
              ? "imageViewer"
              : "imageViewer two"
          }
        >
          {selectedView === "Juweel Ontwerper" && (
            <div className='pageContentContainer'>
              {!imageCreating && (
                <div
                  className='containerForGeneratedImages'
                  style={{
                    flexDirection: mobileDevice ? "column" : "row",
                    justifyContent: "space-between",
                  }}
                >
                  {yourGeneratedImages.length > 0 && (
                    <div
                      className='imageCard'
                      style={{ width: mobileDevice ? "100%" : "48%" }}
                    >
                      <div className='addToSelection'>
                        {!imageCreating && (
                          <div
                            className='addToSelectionButton'
                            onClick={() =>
                              handlePlusClick(yourGeneratedImages[0].imageId)
                            }
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          imageSelectionActiveIndex ===
                          yourGeneratedImages[0].imageId
                            ? "pickSelectionMenu active"
                            : "pickSelectionMenu inactive"
                        }
                      >
                        <div className='selectionPickerHeaderRow'>
                          <div className='selectionPickerHeaderTitle'>
                            Toevoegen aan:
                          </div>
                          <div
                            className='closeXButton'
                            onClick={() => handlePlusClick(null)}
                          >
                            X
                          </div>
                        </div>
                        <div className='listOfAvailableSelections'>
                          {yourSelectionBuckets
                            .sort((a, b) => a.selectionOrder - b.selectionOrder)
                            .map((selectionBucket) => (
                              <div
                                className='SelectionOption'
                                onClick={
                                  selectionBucket.images.some(
                                    (obj) =>
                                      obj.imageId ===
                                      yourGeneratedImages[0].imageId
                                  )
                                    ? () =>
                                        handleRemoveImageFromSelectionButton(
                                          user.email,
                                          selectionBucket.name,
                                          yourGeneratedImages[0]
                                        )
                                    : () =>
                                        handleAddImageToSelectionButton(
                                          user.email,
                                          selectionBucket.name,
                                          yourGeneratedImages[0]
                                        )
                                }
                              >
                                <div>{selectionBucket.name}</div>
                                <div>
                                  <FontAwesomeIcon
                                    icon={
                                      selectionBucket.images.some(
                                        (obj) =>
                                          obj.imageId ===
                                          yourGeneratedImages[0].imageId
                                      )
                                        ? faCheck
                                        : faPlus
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <img
                        src={yourGeneratedImages[0].url}
                        alt={`Generated Jewelry Design`}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  )}
                  {yourGeneratedImages.length > 0 && (
                    <div
                      className='promptCard'
                      style={{ width: mobileDevice ? "100%" : "48%" }}
                    >
                      <p>
                        Klik op het <b>"+"-teken</b> om het juweel toe te voegen
                        aan één van jouw selecties.
                      </p>
                      <p>
                        Klik op <b>"Genereer nieuw juweel"</b> om een nieuw
                        juweel te genereren. Je hebt deze week nog{" "}
                        <b>
                          {10 - numberOfImagesCreatedThisWeek} generaties over.
                        </b>
                      </p>
                      <br />
                      {false && <h5>Gebruike Prompt</h5>}
                      <div className='revisedPromptTextContainer'>
                        {false && !promptIntoEditMode && <p>{editedPrompt}</p>}
                        {false && !promptIntoEditMode && (
                          <div
                            className='editPromptButton'
                            onClick={() =>
                              handleSetPromptIntoEditMode(
                                yourGeneratedImages[0]
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </div>
                        )}
                        {false && promptIntoEditMode && (
                          <textarea
                            value={editedPrompt}
                            onChange={(e) => setEditedPrompt(e.target.value)}
                            rows='10'
                            style={{
                              width: "100%",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          />
                        )}
                        {false && promptIntoEditMode && (
                          <div
                            className='editPromptButton'
                            onClick={() => handleEditPromptSave()}
                          >
                            <FontAwesomeIcon icon={faSave} />
                          </div>
                        )}
                      </div>
                      {false && (
                        <button
                          disabled={promptIntoEditMode ? "true" : "false"}
                          style={{
                            background: promptIntoEditMode
                              ? "rgb(200,200,200)"
                              : "rgb(50,50,50)",
                            cursor: promptIntoEditMode
                              ? "not-allowed"
                              : "pointer",
                          }}
                        >
                          Genereer juweel opnieuw
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
              {imageCreating && (
                <div style={{ textAlign: "center", marginTop: "100px" }}>
                  <Spinner animation='border' role='status'>
                    <span className='visually-hidden'>... Loading</span>
                  </Spinner>
                  <br />
                  <br />
                  Jouw afbeelding wordt op dit moment gegenereerd.
                  <br /> Wacht 20-30 seconden.
                </div>
              )}
            </div>
          )}
          {!imageCreating &&
            selectedView === "Juweel Ontwerper" &&
            yourGeneratedImages.length === 0 && (
              <div className='noImageGeneratedText'>
                <p style={{ textAlign: "center" }}>
                  Momenteel heb je nog geen afbeelding gecreëerd.
                </p>
              </div>
            )}
          {selectedView === "Jouw Ontwerpen" && (
            <div className='pageContentContainer'>
              <h2>Jouw Ontwerpen</h2>
              <div className='pinkUnderline jouwOntwerpen'></div>
              <MasonryLayout>
                {yourGeneratedImages
                  .filter((image) => image.imageDeleted === false)
                  .map((image, index) => (
                    <div className='containerForGeneratedImages'>
                      <div className='imageCard' key={image.imageId}>
                        <div className='addToSelection'>
                          {!imageCreating && (
                            <div
                              className='addToSelectionButton'
                              onClick={() => handlePlusClick(image.imageId)}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            imageSelectionActiveIndex === image.imageId
                              ? "pickSelectionMenu active"
                              : "pickSelectionMenu inactive"
                          }
                        >
                          <div className='selectionPickerHeaderRow'>
                            <div className='selectionPickerHeaderTitle'>
                              Toevoegen aan:
                            </div>
                            <div
                              className='closeXButton'
                              onClick={() => handlePlusClick(null)}
                            >
                              X
                            </div>
                          </div>
                          <div className='listOfAvailableSelections'>
                            {yourSelectionBuckets
                              .sort(
                                (a, b) => a.selectionOrder - b.selectionOrder
                              )
                              .map((selectionBucket) => (
                                <div
                                  className='SelectionOption'
                                  onClick={
                                    selectionBucket.images.some(
                                      (obj) => obj.imageId === image.imageId
                                    )
                                      ? () =>
                                          handleRemoveImageFromSelectionButton(
                                            user.email,
                                            selectionBucket.name,
                                            image
                                          )
                                      : () =>
                                          handleAddImageToSelectionButton(
                                            user.email,
                                            selectionBucket.name,
                                            image
                                          )
                                  }
                                >
                                  <div>{selectionBucket.name}</div>
                                  <div>
                                    <FontAwesomeIcon
                                      icon={
                                        selectionBucket.images.some(
                                          (obj) => obj.imageId === image.imageId
                                        )
                                          ? faCheck
                                          : faPlus
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className='deleteImage'>
                          {!imageCreating && (
                            <div
                              onClick={() => handleImageDelete(image)}
                              className='deleteImageButton'
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </div>
                          )}
                        </div>
                        <img
                          key={index}
                          src={image.url}
                          alt={`Generated Jewelry Design ${index + 1}`}
                          style={{ width: "100%", height: "auto" }}
                        />
                      </div>
                    </div>
                  ))}
              </MasonryLayout>
            </div>
          )}
          {selectedView === "Jouw Ontwerpen" &&
            yourGeneratedImages.length === 0 && (
              <div className='noImageGeneratedText'>
                Momenteel heb je nog geen afbeelding gecreëerd.
              </div>
            )}
          {selectedView === "Andere AI Ontwerpen" && (
            <div className='pageContentContainer'>
              <h2>Andere AI Ontwerpen</h2>
              <div className='pinkUnderline andereAIOntwerpen'></div>
              <MasonryLayout>
                {otherAIGeneratedImages.map((image, index) => (
                  <div className='containerForGeneratedImages'>
                    <div className='imageCard' key={image.imageId}>
                      <div className='addToSelection'>
                        {!imageCreating && (
                          <div
                            className='addToSelectionButton'
                            onClick={() => handlePlusClick(image.imageId)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          imageSelectionActiveIndex === image.imageId
                            ? "pickSelectionMenu active"
                            : "pickSelectionMenu inactive"
                        }
                      >
                        <div className='selectionPickerHeaderRow'>
                          <div className='selectionPickerHeaderTitle'>
                            Toevoegen aan:
                          </div>
                          <div
                            className='closeXButton'
                            onClick={() => handlePlusClick(null)}
                          >
                            X
                          </div>
                        </div>
                        <div className='listOfAvailableSelections'>
                          {yourSelectionBuckets
                            .sort((a, b) => a.selectionOrder - b.selectionOrder)
                            .map((selectionBucket) => (
                              <div
                                className='SelectionOption'
                                onClick={
                                  selectionBucket.images.some(
                                    (obj) => obj.imageId === image.imageId
                                  )
                                    ? () =>
                                        handleRemoveImageFromSelectionButton(
                                          user.email,
                                          selectionBucket.name,
                                          image
                                        )
                                    : () =>
                                        handleAddImageToSelectionButton(
                                          user.email,
                                          selectionBucket.name,
                                          image
                                        )
                                }
                              >
                                <div>{selectionBucket.name}</div>
                                <div>
                                  <FontAwesomeIcon
                                    icon={
                                      selectionBucket.images.some(
                                        (obj) => obj.imageId === image.imageId
                                      )
                                        ? faCheck
                                        : faPlus
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <img
                        key={index}
                        src={image.url}
                        alt={`Generated Jewelry Design ${index + 1}`}
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  </div>
                ))}
              </MasonryLayout>
            </div>
          )}
          {selectedView === "Jouw Selecties" && (
            <div className='pageContentContainer'>
              <div className='pinkUnderline jouwSelecties'></div>
              <h2>Jouw Selecties</h2>
              <div className='selectionBucketTiles'>
                {selectedView === "Jouw Selecties" && (
                  <div className='selectionBucketTileRow'>
                    {yourSelectionBuckets.map((selectionBucket) => (
                      <div className='selectionBucketTile'>
                        <div className='selectionBucketTile-imageContainer'>
                          {selectionBucket.images.map((image) => (
                            <img src={image.url} />
                          ))}
                        </div>
                        {bucketTitleNameInEditMode !== selectionBucket.id && (
                          <div className='selectionBucketTile-bucketTitleRow'>
                            <h3>{selectionBucket.name}</h3>
                            <div
                              className='selectionBucketTile-bucketTitleRow-penIcon'
                              onClick={() =>
                                handleEditBucketTitleToggleEditMode(
                                  selectionBucket
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </div>
                          </div>
                        )}
                        {bucketTitleNameInEditMode === selectionBucket.id && (
                          <div className='selectionBucketTile-bucketTitleRow'>
                            <input
                              value={editedTitle}
                              onChange={(e) => setEditedTitle(e.target.value)}
                              style={{
                                width: "100%",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            ></input>
                            <div
                              className='selectionBucketTile-bucketTitleRow-penIcon'
                              onClick={() =>
                                handleEditBucketTitleSave(selectionBucket)
                              }
                            >
                              <FontAwesomeIcon icon={faSave} />
                            </div>
                          </div>
                        )}
                        <p>
                          {selectionBucket.images.length}{" "}
                          {selectionBucket.images.length === 1
                            ? "item"
                            : "items"}
                        </p>
                        <div className='selectionBucketTile-buttonRow'>
                          <button
                            onClick={() => setSelectedView(selectionBucket.id)}
                          >
                            Bekijk je selectie
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          {yourSelectionBuckets.map((selectionBucket) => (
            <div className='pageContentContainer'>
              {selectedView === selectionBucket.id && (
                <div>
                  <div className='headerRow-selectionDeepDive'>
                    {bucketTitleNameInEditMode !== selectionBucket.id && (
                      <div className='titleWithEditOption'>
                        <h2 className='titleWithEditOption-title'>
                          {selectionBucket.name}
                        </h2>
                        <div
                          className='selectionBucketTile-bucketTitleRow-penIcon'
                          onClick={() =>
                            handleEditBucketTitleToggleEditMode(selectionBucket)
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </div>
                      </div>
                    )}
                    {bucketTitleNameInEditMode === selectionBucket.id && (
                      <div className='titleWithEditOption'>
                        <input
                          value={editedTitle}
                          onChange={(e) => setEditedTitle(e.target.value)}
                          style={{
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                          className='titleWithEditOption-bigTitle'
                        ></input>
                        <div
                          className='selectionBucketTile-bucketTitleRow-penIcon'
                          onClick={() =>
                            handleEditBucketTitleSave(selectionBucket)
                          }
                        >
                          <FontAwesomeIcon icon={faSave} />
                        </div>
                      </div>
                    )}
                  </div>
                  {!selectionBucket.finalDesignRequested && (
                    <div
                      style={{
                        marginBottom: "20px",
                        position: "relative",
                        top: "-30px",
                        marginLeft: "5px",
                      }}
                    >
                      Vraag een finaal ontwerp aan op basis van je geselecteerde
                      afbeeldingen en notities. We bezorgen je zo spoedig
                      mogelijk een finaal ontwerp via email. Dit kan enkele
                      dagen duren.
                    </div>
                  )}
                  <button
                    className='shareSelectionBucket'
                    onClick={() =>
                      handleFinalSelectionBucketSubmit(selectionBucket)
                    }
                    disable={
                      selectionBucket.finalDesignRequested ? "true" : "false"
                    }
                    style={{
                      backgroundColor: selectionBucket.finalDesignRequested
                        ? "rgb(200,200,200)"
                        : "rgb(0, 0, 0)",
                    }}
                  >
                    {selectionBucket.finalDesignRequested
                      ? "Ontwerp Aangevraagd"
                      : "Vraag finaal ontwerp"}
                  </button>
                  {selectionBucket.finalDesignRequested && (
                    <div
                      style={{
                        marginBottom: "20px",
                        position: "relative",
                        top: "-10px",
                        marginLeft: "5px",
                      }}
                    >
                      Een finaal ontwerp op basis van je geselecteerde
                      afbeeldingen en notities is aangevraagd. We bezorgen je zo
                      spoedig mogelijk een finaal ontwerp via email. Dit kan
                      enkele dagen duren.
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {selectedView === selectionBucket.id && (
                      <div className='selectionBucketDeepDive'>
                        {yourSelectionBuckets.map((selectionBucket, index) => (
                          <MasonryLayout>
                            {selectedView === selectionBucket.id &&
                              selectionBucket.images.map((image) => (
                                <div
                                  className='containerForGeneratedImages'
                                  style={{
                                    marginBottom: mobileDevice ? "20px" : "0px",
                                  }}
                                >
                                  <div
                                    className='imageCard'
                                    key={image.imageId}
                                  >
                                    <div className='addToSelection'>
                                      {!imageCreating &&
                                        !selectionBucket.finalDesignRequested && (
                                          <div
                                            className='addToSelectionButton'
                                            onClick={() =>
                                              handleRemoveImageFromSelectionButton(
                                                user.email,
                                                selectionBucket.name,
                                                image
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon={faMinus} />
                                          </div>
                                        )}
                                    </div>
                                    <img
                                      key={index}
                                      src={image.url}
                                      alt={`Generated Jewelry Design ${
                                        index + 1
                                      }`}
                                      style={{ width: "100%", height: "auto" }}
                                    />
                                  </div>
                                  {cardInCommentEditMode !== image.imageId && (
                                    <div className='commentArea'>
                                      {image.comment ? (
                                        <p>{image.comment}</p>
                                      ) : (
                                        <p
                                          style={{
                                            color: "rgb(200,200,200)",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          Plaats hier een notitie om aan te
                                          geven wat je mooi of niet mooi vindt
                                          aan dit ontwerp.
                                        </p>
                                      )}
                                      <div
                                        className='editComment'
                                        onClick={() =>
                                          handleToggleEditMode(
                                            image,
                                            selectionBucket
                                          )
                                        }
                                      >
                                        <div>
                                          <FontAwesomeIcon icon={faPen} />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {cardInCommentEditMode === image.imageId && (
                                    <div className='commentArea'>
                                      <textarea
                                        value={editedComment}
                                        onChange={(e) =>
                                          setEditedComment(e.target.value)
                                        }
                                        rows='4'
                                        style={{
                                          width: "80%",
                                          padding: "5px",
                                          borderRadius: "5px",
                                        }}
                                      />
                                      <div
                                        className='editComment'
                                        onClick={() =>
                                          handleCommentSave(
                                            image,
                                            selectionBucket
                                          )
                                        }
                                      >
                                        <div>
                                          <FontAwesomeIcon icon={faSave} />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                          </MasonryLayout>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JewelryDesignerForm;
