import React from "react";
import { Link } from "react-scroll";
import "./LandingPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import handsAndJewelry from "../assets/Hands&Jewelry.png";
import giftBox from "../assets/giftBox.jpg";

const LandingPage = ({
  setOverOnsActive,
  oneOfAKindActive,
  setOneOfAKindActive,
}) => {
  return (
    <div className='landingPage'>
      <div className='landingPage-titleRae'>
        <h1>rae,</h1>
      </div>
      <div className='landingPage-titleTagline'>
        <h2>a drop of golden sun</h2>
      </div>
      <div className='landingPage-introText'>
        <span className='landingPage-introText-desktop'>
          <p>
            Wat het leven ook brengt, er is altijd een uniek juweel
            <br />
            dat jouw verhaal kan vertellen en je hart kan verwarmen.
          </p>
        </span>
        <span className='landingPage-introText-mobile'>
          <p>
            Een uniek juweel dat jouw verhaal kan vertellen
            <br />
            en je hart kan verwarmen.
          </p>
        </span>
      </div>
      <div className='landingPage-introCallToActionCollection'>
        <div className='landingPage-callToAction-circleButton'>
          <FontAwesomeIcon className='arrowIcon' icon={faCaretDown} />
        </div>
        <button className='landingPage-introCallToActionCollection-button'>
          <Link to='raeCollection' smooth={false} duration={500}>
            bekijk onze collectie
          </Link>
        </button>
      </div>
      <div className='landingPage-heroImage'>
        <img src={handsAndJewelry} alt='Hands in the air showing rae jewelry' />
      </div>
      <div className='landingPage-sideImage'>
        <img src={giftBox} alt='Rae Giftbox' />
      </div>
      <div
        className='landingPage-introCallToActionOneOfAKind'
        onClick={() => {
          setOverOnsActive(false);
          setOneOfAKindActive(!oneOfAKindActive);
        }}
      >
        <button className='landingPage-introCallToActionOneOfAKind-button'>
          of contacteer ons
          <br />
          voor een juweel
          <br />
          op maat
        </button>
        <div className='landingPage-callToAction-circleButton'>
          <FontAwesomeIcon className='arrowIcon' icon={faCaretLeft} />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
