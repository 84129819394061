import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./RaeTermsAndConditions.css";

export default function RaeTermsAndConditions({
  termsAndConditionsActive,
  setTermsAndConditionsActive,
}) {
  return (
    <>
      {termsAndConditionsActive && (
        <div className='sideModal RaeTermsAndConditions'>
          <button
            onClick={() => setTermsAndConditionsActive(false)}
            className='itemModal-closeButton'
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className='RaeTermsAndConditions-content'>
            <h1>ALGEMENE VERKOOPSVOORWAARDEN</h1>
            <ol>
              <li>Verkoper</li>
              <p>
                Welkom op onze website rae.gold.
                <br />
                rae.gent is eigendom van Marbleous Concept Agency
                <br />
                BE0761777721
                <br />
                e-mailadres hello@rae.gold
                <br />
              </p>
              <li>Algemeen</li>
              <p>
                Deze voorwaarden zijn van toepassing op alle bestellingen via
                rae.gold en overeenkomsten tussen de klant en rae. Door een
                bestelling te plaatsen gaat de klant volledig akkoord met de
                hieronder vermelde verkoopsvoorwaarden. Rae behoudt zich het
                recht om de verkoopsvoorwaarden op eender welk moment te
                wijzigen.{" "}
              </p>
              <p>
                Indien rae de bestelling om een niet nader gespecificeerde reden
                niet kan uitvoeren, laten we dit zo snel mogelijk weten aan de
                klant - zonder dat deze laatste aanspraak kan maken op enige
                vorm van schadevergoeding.
              </p>
              <li>Offertes</li>
              <p>
                Opgemaakte offertes voor op maat gemaakte juwelen
                (‘one-of-a-kind’) blijven één maand geldig. De klant dient het
                totaalbedrag van de offerte te betalen binnen deze termijn
                indien hij wenst over te gaan tot bestelling.{" "}
              </p>
              <li>Productomschrijving en prijzen</li>
              <p>
                Alle producten die door rae aangeboden worden, zijn ter goeder
                trouw beschreven. Rae behoudt ten allen tijde het recht om
                inhoud van de website te wijzigen. Dit betekent dat de
                informatie omtrent de producten en prijzen onder voorbehoud van
                wijziging zijn.
              </p>
              <p>
                De op de website vermelde prijzen zijn inclusief 21% BTW en
                exclusief verzendkosten. De verzendkosten worden bij bestelling
                getoond aan de klant.
              </p>
              <li>Levering</li>
              <p>
                De levertermijn staat gespecifieerd bij de verschillende items
                die aangeboden worden op de website. Voor op maat gemaakte
                juwelen (‘one-of-a-kind’) wordt een geschatte levertermijn
                aangegeven bij het bezorgen van de offerte. Leveringen gebeuren
                op het leveringsadres gespecificeerd door de klant in zijn
                bestelling. Foutief doorgegeven leveringsadressen zijn de
                verantwoordelijkheid van de klant en kunnen aanleiding geven tot
                extra kosten.
              </p>
              <p>
                De aangeduide levertermijn is een schatting die ter goeder trouw
                gemaakt is. Indien de levertermijn langer is dan verwacht, wordt
                dit zo spoedig mogelijk aan de klant meegedeeld. De klant heeft
                echter in geen enkel geval recht op schadevergoeding noch
                terugbetaling voor het overschrijden van leveringstermijn.
              </p>
              <p>
                Rae kan niet verantwoordelijk worden geacht voor beschadiging,
                diefstal of het verlies van goederen tijdens de verzending. Wij
                doen er alles aan om de goederen zo goed en zo veilig mogelijk
                te verpakken. De klant dient de staat van de geleverde goederen
                na te kijken wanneer deze worden overhandigd door de
                transporteur. Wanneer de verpakking van de geleverde goederen
                geopend en/ of beschadigd is, is de klant verplicht dit te
                melden aan de vervoersmaatschappij. Dit dient dan vermeld te
                worden op de verzendnota op het ogenblik van in ontvangstneming.
                Elk pakket kan online worden opgevolgd aan de hand van een Track
                & Trace code. In geval van problemen met de bezorging van het
                verstuurde pakket, kan de klant zich wenden tot de transporteur
                met vermelding van deze barcode.
              </p>
              <li>Betaalmogelijkheden</li>
              <p>
                Bestellingen via de online webshop kunnen betaald worden via het
                stripe betalingssysteem. Bestellingen van op maat gemaakte
                juwelen (‘one-of-a-kind’) zullen enkel uitgevoerd worden wanneer
                de betaling van de bestelling conform de offerte geregistreerd
                is op onze rekening BE74068940034707.{" "}
              </p>
              <li>Retour en terugbetaling</li>
              <p>
                Aangezien we werken met op maat gemaakte juwelen en juwelen met
                gepersonaliseerde opties (bijvoorbeeld specifieke maat van
                ring/armband, gravures, slagletters) kunnen deze niet
                geretourneerd worden. Ook oorbellen worden niet geretourneerd
                omwille van hygiënische redenen. Dit wil dan ook zeggen dat de
                klant geen terugbetaling van aangeschafte producten kan eisen.
              </p>
              <li>Privacy</li>
              <p>
                Om de klant zo goed mogelijk verder te helpen en de bestelling,
                facturatie en levering vlot te laten verlopen, heeft rae enkele
                persoonlijke gegevens nodig. Door gebruik te maken van de
                webshop stemt de klant in met het verzamelen, gebruik en opslaan
                van deze persoonlijke gegevens. Rae houdt deze gegevens privé en
                geeft deze niet door aan derden.{" "}
              </p>
              <p>
                De klant heeft steeds het recht om zijn gegevens in te kijken,
                te corrigeren of te verwijderen uit ons bestand.{" "}
              </p>
              <br />
              <br />
              <p>
                Indien u verdere vragen heeft in verband met de hierboven
                genoemde algemene verkoopsvoorwaarden, kan u steeds contact met
                ons opnemen via hello@rae.gold
              </p>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}
