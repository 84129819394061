import React from "react";
import { useState } from "react";
import { db, storage } from "../firebase/config";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import OpenAI from "openai";
import axios from "axios";

export default function JewelryDesignForm({
  user,
  yourGeneratedImages,
  setYourGeneratedImages,
  imageCreating,
  setImageCreating,
  setSelectedView,
}) {
  const [jewelryType, setJewelryType] = useState("");
  const [ringCenterPieceStone, setRingCenterPieceStone] =
    useState("No Gemstone");
  const [ringCenterPieceStoneCut, setRingCenterPieceStoneCut] =
    useState("Round Brillant");
  const [ringMetalType, setRingMetalType] = useState("Golden");
  const [earringMetalType, setEarringMetalType] = useState("Golden");
  const [necklaceMetalType, setNecklaceMetalType] = useState("Golden");
  const [braceletMetalType, setBraceletMetalType] = useState("Golden");
  const [ringBandFinish, setRingBandFinish] = useState("Polished");
  const [earringType, setEarringType] = useState("Hoop");
  const [earringGemstone, setEarringGemstone] = useState("No Gemstone");
  const [earringGemstoneShape, setEarringGemstoneShape] = useState("Round");
  const [braceletType, setBraceletType] = useState("Slender Chain");
  const [braceletGemstone, setBraceletGemstone] = useState("No Gemstone");
  const [braceletGemstoneShape, setBraceletGemstoneShape] = useState("Round");
  const [necklaceType, setNecklaceType] = useState("No Gemstone");
  const [necklaceGemstone, setNecklaceGemstone] = useState("White Diamond");
  const [necklaceGemstoneShape, setNecklaceGemstoneShape] = useState("Round");

  const handleCreateImage = async (event) => {
    event.preventDefault();
    setImageCreating(true);
    try {
      const response = await createImage();
      const storageImageUrl =
        await transformBase64ImageResponseAndGetFirebaseStorageUrl(
          response,
          user
        );
      await saveGeneratedImageToFirestore(response, user, storageImageUrl);
      // function to change the yourGeneratedImages state
      const yourGeneratedImagesUpdate = [
        ...yourGeneratedImages,
        {
          url: storageImageUrl,
          greenVoted: false,
          selectedForGallery: false,
          imageDeleted: false,
          creator: user.email,
          generationDate: new Date(),
          imageId: response.data.created,
          comment: null,
          revised_prompt: response.data.data[0].revised_prompt,
        },
      ];
      setYourGeneratedImages(yourGeneratedImagesUpdate);
      setImageCreating(false);
    } catch (error) {
      console.error("Error creating image: ", error);
    }
  };

  const createImage = async () => {
    setImageCreating(true);
    const openaiApi = axios.create({
      baseURL: "https://api.openai.com/v1/",
      headers: {
        Authorization: `Bearer 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`,
        organization: "org-h1bJK5iOzVXDfN6bVEer5gaP",
        "Content-Type": "application/json",
      },
    });
    try {
      const promptRing = `Create a photorealistic image of an elegant, minimalistic ring based on the following specifications:

      Metal: The ring should be made of ${ringMetalType}, capturing the unique sheen and color of this metal.
      Jewelry Type: The design is for a ${jewelryType}, characterized by its simplicity and elegance.
      ${
        ringCenterPieceStone === "No Gemstone"
          ? `The ${ringMetalType} ${jewelryType} should have no gemstones`
          : `include a subtle, small ${ringCenterPieceStone} (no more than 5% of the ring's diameter). It definitely should be in a classic Prong Setting. It should have a ${ringCenterPieceStoneCut}.`
      }
      Ring Band: The ${ringMetalType} band should be extremely slender and refined, with a ${ringBandFinish} finish that complements the overall design.
      Presentation: Display the ${jewelryType} lying flat on a light, elegant background like marble to highlight delicate and refined features.`;

      const promptNecklace = `Create a photorealistic image of an elegant, minimalistic necklace based on the following specifications:

      Metal: The necklace should be made of ${necklaceMetalType}, capturing the unique sheen and color of this metal.
      Jewelry Type: The design is for a ${jewelryType}, characterized by its simplicity and elegance.
      ${
        jewelryType === "No Gemstone"
          ? `The ${jewelryType} should be pure ${necklaceMetalType}, without any gemstones. It can include a hanger or pendant, but the focus should be on the minimalistic design.`
          : `incorporate a subtle, small ${necklaceGemstoneShape}-shaped ${necklaceGemstone} in the design.`
      }
      Necklace Type: The ${necklaceMetalType} necklace should showcase a ${necklaceType} style (with a slender golden chain), blending seamlessly with the overall minimalistic design.
      Presentation: Display the ${jewelryType} on a light, elegant background or on a model, positioned to highlight its delicate and refined features.`;

      const promptEarring = `Create a photorealistic image of an elegant, minimalistic earring based on the following specifications:

      Metal: The earring should be made of ${earringMetalType}, capturing the unique sheen and color of this metal.
      Jewelry Type: The design is for a ${jewelryType}, characterized by its simplicity and elegance.
      ${
        earringType === "Huggie" || earringGemstone === "No Gemstone"
          ? `The ${jewelryType} should be pure ${earringMetalType}. It should have no gemstones`
          : `incorporate a subtle, small ${earringGemstoneShape}-shaped ${earringGemstone} in the design.`
      }
      Earring Type: The ${earringMetalType} earring should showcase a ${earringType} style, blending seamlessly with the overall minimalistic design.
      Presentation: Display the ${jewelryType} on a woman's ear. The earring should be presented in a way that highlights its features, such as a close-up shot of the earring on the ear.`;

      const promptBracelet = `Create a photorealistic image of an elegant, minimalistic bracelet based on the following specifications:

      Metal: The necklace should be made of ${braceletMetalType}, capturing the unique sheen and color of this metal.
      Jewelry Type: The design is for a ${jewelryType}, characterized by its simplicity and elegance.
      ${
        braceletType === "Bangle" ||
        braceletType === "Open Bangle" ||
        braceletType === "Bar" ||
        braceletGemstone === "No Gemstone"
          ? `The ${jewelryType} should be pure ${braceletMetalType}, without any gemstones.`
          : `incorporate a subtle, small ${braceletGemstoneShape}-shaped ${braceletGemstone} in the slender golden chain design.`
      }
      Bracelet Type: The ${braceletMetalType} necklace should showcase a ${braceletType} style, blending seamlessly with the overall minimalistic design.
      Presentation: Display the ${jewelryType} on a light, elegant background or on a models wrist, positioned to highlight the bracelet's delicate and refined features.`;

      const response = await openaiApi.post("images/generations", {
        model: "dall-e-3",
        prompt: `${jewelryType === "Ring" ? promptRing : ""} ${
          jewelryType === "Earring" ? promptEarring : ""
        } ${jewelryType === "Bracelet" ? promptBracelet : ""} ${
          jewelryType === "Necklace" ? promptNecklace : ""
        }`,
        n: 1,
        size: "1024x1024",
        response_format: "b64_json",
      });
      return response;
    } catch (error) {
      console.log(error);
      setImageCreating(false);
      throw error;
    }
  };

  const transformBase64ImageResponseAndGetFirebaseStorageUrl = async (
    response,
    user
  ) => {
    try {
      const uniqueId = new Date().getTime(); // Unique identifier for the image
      const storagePath = `/generatedImages/${user.email}/${uniqueId}.png`;

      // Convert base64 to a Blob
      console.log(response.data.data[0].b64_json);
      const fetchResponse = await fetch(
        `data:image/png;base64,${response.data.data[0].b64_json}`
      );
      const blob = await fetchResponse.blob();

      const storageRef = ref(storage, storagePath);
      await uploadBytes(storageRef, blob);

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error in uploading base64 image:", error);
      throw error;
    }
  };

  const saveGeneratedImageToFirestore = async (
    response,
    user,
    storageImageUrl
  ) => {
    try {
      const generatedImages = collection(db, "generatedImages");
      await addDoc(generatedImages, {
        promptDetails: {
          jewelrytype: jewelryType,
          metalType: ringMetalType,
          centerPieceStone: ringCenterPieceStone,
          centerPieceStoneCut: ringCenterPieceStoneCut,
          ringBandFinish: ringBandFinish,
        },
        url: storageImageUrl,
        revised_prompt: response.data.data[0].revised_prompt,
        imageId: response.data.created,
        selectedForGallery: false,
        creator: user.email,
        imageDeleted: false,
        comment: null,
        generationDate: new Date(),
      });
    } catch (error) {
      console.log("error with uploading image to fireabse", error);
      throw error;
    }
  };

  return (
    <form className='jewelry-designer-form'>
      <h4>AI Juweel Ontwerper</h4>
      <p>
        Gebruik onderstaand selectie menu om jouw voorkeuren aan te geven. Op
        basis van jouw selectie, probeert ons AI model voor jou een unieke
        afbeelding te creëren. Onze afbeelding generator is momenteel nog in
        beta fase waardoor er soms nog een kleine afwijking kan zitten tussen
        jouw vraag en het gegenereerde ontwerp. Indien de afbeelding afwijkt van
        jouw vraag, probeer het nogmaals te generenen met dezelfde settings.
      </p>
      <label>
        Jewelry Type:
        <select
          value={jewelryType}
          onChange={(e) => setJewelryType(e.target.value)}
        >
          <option value=''>Maak een keuze</option>
          <option value='Ring'>Ring</option>
          <option value='Earring'>Earring</option>
          <option value='Bracelet'>Bracelet</option>
          <option value='Necklace'>Necklace</option>
        </select>
      </label>
      {jewelryType === "Ring" && (
        <label>
          Metal Type:
          <select
            value={ringMetalType}
            onChange={(e) => setRingMetalType(e.target.value)}
          >
            <option value='Yellow Golden'>Gold</option>
            <option value='Rosé Golden (pink)'>Rose Gold</option>
            <option value='White Golden'>White Gold</option>
            <option value='Silveren'>Silver</option>
          </select>
        </label>
      )}
      {jewelryType === "Ring" && (
        <label>
          Center Gemstone:
          <select
            value={ringCenterPieceStone}
            onChange={(e) => setRingCenterPieceStone(e.target.value)}
          >
            <option value='White Diamond'>White Diamond</option>
            <option value='Red Ruby'>Brown Diamond</option>
            <option value='Yellow Sapphire'>Yellow Sapphire</option>
            <option value='Blue Sapphire'>Blue Sapphire</option>
            <option value='Green Sapphire'>Green Sapphire</option>
            <option value='Pink Sapphire'>Pink Sapphire</option>
            <option value='Pink Tourmaline'>Pink Tourmaline</option>
            <option value='Green Tourmaline'>Green Tourmaline</option>
            <option value='Yellow Citrine'>Yellow Citrine</option>
            <option value='Purple Amethyst'>Purple Amethyst</option>
            <option value='Blue Topaz'>Blue Topaz</option>
            <option value='Blue Tanzanite'>Blue Tanzanite</option>
            <option value='Green Peridot'>Green Peridot</option>
            <option value='Green Emerald'>Green Emerald</option>
            <option value='Red Ruby'>Red Ruby</option>
            <option value='No Gemstone'>No Gemstone</option>
            <option value=''>No Preference</option>
          </select>
        </label>
      )}
      {jewelryType === "Ring" && ringCenterPieceStone !== "No Gemstone" && (
        <label>
          Gemstone Cut:
          <select
            value={ringCenterPieceStoneCut}
            onChange={(e) => setRingCenterPieceStoneCut(e.target.value)}
          >
            <option value='Round Brilliant Cut'>Round Brilliant</option>
            <option value='Emerald'>Emerald</option>
            <option value='Princess'>Princess</option>
            <option value='Oval'>Oval</option>
            <option value='Marquise'>Marquise</option>
            <option value='Pear'>Pear</option>
            <option value='Baguette'>Baguette</option>
          </select>
        </label>
      )}
      {jewelryType === "Ring" && (
        <label>
          Finish:
          <select
            value={ringBandFinish}
            onChange={(e) => setRingBandFinish(e.target.value)}
          >
            <option value='Polished'>Polished</option>
            <option value='Matte (rough finish, not polished)'>Matte</option>
            <option value='Hammered (i.e. very subtle, hardly visible, naturaly looking dents into a polished ring band)'>
              Hammered
            </option>
            <option value='Twisted (i.e. two slender ring bands twisted around eachother)'>
              Twisted
            </option>
            <option value='A slender ring band partially paved with subtle small gemstones - not the full ring band covered'>
              Band with subtle stones
            </option>
            <option value='A slender ring band paved with subtle small gemstones in multiple colors'>
              Band with multicolored stones
            </option>
          </select>
        </label>
      )}
      {jewelryType === "Earring" && (
        <label>
          Metal Type:
          <select
            value={earringMetalType}
            onChange={(e) => setEarringMetalType(e.target.value)}
          >
            <option value='Yellow Golden'>Gold</option>
            <option value='Rosé Golden (pink)'>Rose Gold</option>
            <option value='White Golden'>White Gold</option>
            <option value='Silveren'>Silver</option>
          </select>
        </label>
      )}
      {jewelryType === "Earring" && (
        <label>
          Earring Type:
          <select
            value={earringType}
            onChange={(e) => setEarringType(e.target.value)}
          >
            <option value='Hoops'>Hoops</option>
            <option value='Square Hoops'>Square Hoops</option>
            <option value='Jacket'>Jacket</option>
            <option value='Huggie'>Huggie</option>
            <option value='Drop'>Drop</option>
          </select>
        </label>
      )}
      {jewelryType === "Earring" && earringType !== "Huggie" && (
        <label>
          Gemstone:
          <select
            value={earringGemstone}
            onChange={(e) => setEarringGemstone(e.target.value)}
          >
            <option value='White Diamond'>White Diamond</option>
            <option value='Red Ruby'>Brown Diamond</option>
            <option value='Yellow Sapphire'>Yellow Sapphire</option>
            <option value='Blue Sapphire'>Blue Sapphire</option>
            <option value='Green Sapphire'>Green Sapphire</option>
            <option value='Pink Sapphire'>Pink Sapphire</option>
            <option value='Pink Tourmaline'>Pink Tourmaline</option>
            <option value='Green Tourmaline'>Green Tourmaline</option>
            <option value='Yellow Citrine'>Yellow Citrine</option>
            <option value='Purple Amethyst'>Purple Amethyst</option>
            <option value='Blue Topaz'>Blue Topaz</option>
            <option value='Blue Tanzanite'>Blue Tanzanite</option>
            <option value='Green Peridot'>Green Peridot</option>
            <option value='Green Emerald'>Green Emerald</option>
            <option value='Red Ruby'>Red Ruby</option>
            <option value='No Gemstone'>No Gemstone</option>
            <option value=''>No Preference</option>
          </select>
        </label>
      )}
      {jewelryType === "Earring" &&
        earringType !== "Huggie" &&
        earringGemstone !== "No Gemstone" && (
          <label>
            Gemstone Shape:
            <select
              value={earringGemstoneShape}
              onChange={(e) => setEarringGemstoneShape(e.target.value)}
            >
              <option value='Round'>Round</option>
              <option value='Square'>Square</option>
              <option value='Oval'>Oval</option>
              <option value='Rectangular'>Rectangular</option>
              <option value='Pear'>Pear</option>
              <option value='Triangular'>Triangular</option>
            </select>
          </label>
        )}
      {jewelryType === "Bracelet" && (
        <label>
          Bracelet Type:
          <select
            value={braceletType}
            onChange={(e) => setBraceletType(e.target.value)}
          >
            <option value='Slender Chain Bracelet'>Slender Chain</option>
            <option value='Bangle'>Bangle</option>
            <option value='Open Bangle'>Open Bangle</option>
            <option value='Birthstone'>Birthstone</option>
            <option value='Bar (i.e. a slender chain with a bar-shaped element connecting the chain)'>
              Bar
            </option>
          </select>
        </label>
      )}
      {jewelryType === "Bracelet" && (
        <label>
          Metal Type:
          <select
            value={braceletMetalType}
            onChange={(e) => setBraceletMetalType(e.target.value)}
          >
            <option value='Yellow Golden'>Gold</option>
            <option value='Rosé Golden (pink)'>Rose Gold</option>
            <option value='White Golden'>White Gold</option>
            <option value='Silveren'>Silver</option>
          </select>
        </label>
      )}
      {((jewelryType === "Bracelet" && braceletType === "Birthstone") ||
        (jewelryType === "Bracelet" &&
          braceletType === "Slender Chain Bracelet")) && (
        <label>
          Gemstone:
          <select
            value={braceletGemstone}
            onChange={(e) => setBraceletGemstone(e.target.value)}
          >
            <option value='White Diamond'>White Diamond</option>
            <option value='Red Ruby'>Brown Diamond</option>
            <option value='Yellow Sapphire'>Yellow Sapphire</option>
            <option value='Blue Sapphire'>Blue Sapphire</option>
            <option value='Green Sapphire'>Green Sapphire</option>
            <option value='Pink Sapphire'>Pink Sapphire</option>
            <option value='Pink Tourmaline'>Pink Tourmaline</option>
            <option value='Green Tourmaline'>Green Tourmaline</option>
            <option value='Yellow Citrine'>Yellow Citrine</option>
            <option value='Purple Amethyst'>Purple Amethyst</option>
            <option value='Blue Topaz'>Blue Topaz</option>
            <option value='Blue Tanzanite'>Blue Tanzanite</option>
            <option value='Green Peridot'>Green Peridot</option>
            <option value='Green Emerald'>Green Emerald</option>
            <option value='Red Ruby'>Red Ruby</option>
            <option value='No Gemstone'>No Gemstone</option>
            <option value=''>No Preference</option>
          </select>
        </label>
      )}
      {((jewelryType === "Bracelet" &&
        braceletType === "Birthstone" &&
        braceletGemstone !== "No Gemstone") ||
        (jewelryType === "Bracelet" &&
          braceletType === "Slender Chain Bracelet" &&
          braceletGemstone !== "No Gemstone")) && (
        <label>
          Gemstone Shape:
          <select
            value={braceletGemstoneShape}
            onChange={(e) => setBraceletGemstoneShape(e.target.value)}
          >
            <option value='Round'>Round</option>
            <option value='Square'>Square</option>
            <option value='Oval'>Oval</option>
            <option value='Rectangular'>Rectangular</option>
            <option value='Pear'>Pear</option>
            <option value='Triangular'>Triangular</option>
          </select>
        </label>
      )}
      {jewelryType === "Necklace" && (
        <label>
          Necklace Type:
          <select
            value={necklaceType}
            onChange={(e) => setNecklaceType(e.target.value)}
          >
            <option value='Slender Chain'>Chain</option>
            <option value='Solitaire'>Solitaire</option>
            <option value='Birthstone'>Birthstone</option>
          </select>
        </label>
      )}
      {jewelryType === "Necklace" && (
        <label>
          Metal Type:
          <select
            value={necklaceMetalType}
            onChange={(e) => setNecklaceMetalType(e.target.value)}
          >
            <option value='Yellow Golden'>Gold</option>
            <option value='Rosé Golden (pink)'>Rose Gold</option>
            <option value='White Golden'>White Gold</option>
            <option value='Silveren'>Silver</option>
          </select>
        </label>
      )}
      {((jewelryType === "Necklace" && necklaceType === "Slender Chain") ||
        (jewelryType === "Necklace" && necklaceType === "Birthstone") ||
        (jewelryType === "Necklace" && necklaceType === "Solitaire")) && (
        <label>
          Gemstone:
          <select
            value={necklaceGemstone}
            onChange={(e) => setNecklaceGemstone(e.target.value)}
          >
            <option value='White Diamond'>White Diamond</option>
            <option value='Red Ruby'>Brown Diamond</option>
            <option value='Yellow Sapphire'>Yellow Sapphire</option>
            <option value='Blue Sapphire'>Blue Sapphire</option>
            <option value='Green Sapphire'>Green Sapphire</option>
            <option value='Pink Sapphire'>Pink Sapphire</option>
            <option value='Pink Tourmaline'>Pink Tourmaline</option>
            <option value='Green Tourmaline'>Green Tourmaline</option>
            <option value='Yellow Citrine'>Yellow Citrine</option>
            <option value='Purple Amethyst'>Purple Amethyst</option>
            <option value='Blue Topaz'>Blue Topaz</option>
            <option value='Blue Tanzanite'>Blue Tanzanite</option>
            <option value='Green Peridot'>Green Peridot</option>
            <option value='Green Emerald'>Green Emerald</option>
            <option value='Red Ruby'>Red Ruby</option>
            {necklaceType === "Slender Chain" && (
              <option value='No Gemstone'>No Gemstone</option>
            )}
            <option value=''>No Preference</option>
          </select>
        </label>
      )}
      {jewelryType === "Necklace" && necklaceGemstone !== "No Gemstone" && (
        <label>
          Gemstone Shape:
          <select
            value={necklaceGemstoneShape}
            onChange={(e) => setNecklaceGemstoneShape(e.target.value)}
          >
            <option value='Round'>Round</option>
            <option value='Square'>Square</option>
            <option value='Oval'>Oval</option>
            <option value='Rectangular'>Rectangular</option>
            <option value='Pear'>Pear</option>
            <option value='Triangular'>Triangular</option>
          </select>
        </label>
      )}
      {/* Repeat this structure for other categories */}
      {/* ... */}
      <button
        type='submit'
        value='Genereer Juweel'
        disabled={jewelryType === "" || imageCreating}
        onClick={handleCreateImage}
        className='imageCreationButton'
      >
        {!imageCreating && <span>Genereer nieuw juweel</span>}
        {imageCreating && <span>Juweel wordt gegenereerd...</span>}
      </button>
    </form>
  );
}
