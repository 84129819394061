import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { db, auth } from "../firebase/config";
import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { signInAnonymously } from "firebase/auth";
import { Form, Button, Container } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faBagShopping,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

// import styling
import "bootstrap/dist/css/bootstrap.min.css";
import "./RaeShoppingCart.css";

const RaeShoppingCart = ({
  cart,
  cartCount,
  handleDeleteFromCart,
  cartModalActive,
  setCartModalActive,
  setNavMenuActive,
  setShopItemModalActive,
  toggleCartModal,
}) => {
  const [paymentPageLoading, setPaymentPageLoading] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  // handle function
  const handleCartClose = () => {
    setCartModalActive(false);
    setShopItemModalActive(false);
  };

  useEffect(() => {
    cart.forEach((item) => {
      for (let key in item) {
        if (item[key] === undefined) {
          console.log(`Undefined found in item property: ${key}`);
        }
      }
    });
  }, [cart]);

  // Calculate total price from the cart array
  const totalPrice = cart.reduce((acc, item) => acc + item.totalPrice, 0);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  // function to handle anonymous registration and creditCard payment
  const handleRegistrationAndCreditCardPayment = async () => {
    setPaymentPageLoading(true);
    setPaymentType("kredietkaart");

    try {
      await signInAnonymously(auth);

      const userRef = doc(db, "customers", auth.currentUser.uid);
      const customerDoc = await getDoc(userRef);

      if (!customerDoc.exists()) {
        // If the customer doesn't exist, set a new one
        await setDoc(userRef, {
          userUid: auth.currentUser.uid,
        });
      }

      // Add new order to the 'orders' collection in firestore
      const ordersCollectionRef = collection(db, "orders");
      await addDoc(ordersCollectionRef, {
        order: cart,
        orderDate: new Date(), // This is optional but helps in organizing orders chronologically
        userId: auth.currentUser.uid,
      });

      const filteredCartItems = cart.filter((item) => item.quantity > 0);
      const checkoutItems = filteredCartItems.map((item) => ({
        price: item.itemPriceStripeId,
        quantity: item.quantity,
      }));

      const paymentDocRef = await addDoc(
        collection(db, `customers/${auth.currentUser.uid}/checkout_sessions`),
        {
          mode: "payment",
          line_items: checkoutItems,
          shipping_address_collection: {
            allowed_countries: ["BE", "NL"],
          },
          allow_promotion_codes: true, // Enable promotion codes
          success_url: `${window.location.origin}/success`,
          cancel_url: `${window.location.origin}`,
        }
      );

      onSnapshot(paymentDocRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
        }
      });
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(
        "error when handling customer registration and payment",
        errorCode,
        errorMessage
      );
    }
  };

  return (
    <div className='RaeShoppingCart'>
      <button onClick={toggleCartModal} className='cartButton'>
        <FontAwesomeIcon icon={faBagShopping} />
        <div className='cartButton-counter'>{cart.length}</div>
      </button>

      {cartModalActive && (
        <div className='sideModal cart'>
          <button onClick={handleCartClose} className='cartCloseButton'>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h2>Winkelmandje</h2>
          {cart.length === 0 ? (
            <p style={{ marginTop: "20px", marginLeft: "10px" }}>
              Je winkelmandje is leeg
            </p>
          ) : (
            <div className='sideModal-cart-content'>
              <div className='sideModal-cart-items'>
                {cart.map((item, index) => (
                  <div key={index} className='sideModal-cart-item'>
                    <div className='sideModal-cart-item-imageContainer'>
                      <img
                        src={item.image}
                        className='sideModal-cart-item-image'
                      />
                    </div>
                    <div className='sideModal-cart-item-specContainer'>
                      <div className='sideModal-cart-item-name'>
                        {item.quantity} X {item.name}
                      </div>
                      {item.size && (
                        <div className='sideModal-cart-item-size'>
                          Maat: {item.size}
                        </div>
                      )}
                      <div className='sideModal-cart-item-material'>
                        {item.material}
                      </div>
                    </div>
                    <div className='sideModal-cart-item-subTotalPriceContainer'>
                      <div className='sideModal-cart-item-subTotalPrice'>
                        € {item.totalPrice}
                      </div>
                    </div>
                    <div
                      className='sideModal-cart-item-deleteButtonContainer'
                      onClick={() => handleDeleteFromCart(index)}
                    >
                      <div className='sideModal-cart-item-deleteButton'>
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='sideModal-cart-bottomCheckout'>
                <div className='sideModal-cart-bottomCheckout-totalPrice'>
                  <div>Totaal:</div>
                  <div>€{totalPrice}</div>
                </div>

                {!paymentPageLoading && (
                  <Button
                    onClick={handleRegistrationAndCreditCardPayment}
                    className='sideModal-cart-bottomCheckout-checkoutButton'
                  >
                    Afrekenen
                  </Button>
                )}
                {paymentPageLoading && (
                  <Button
                    disabled
                    onClick={handleRegistrationAndCreditCardPayment}
                    className='sideModal-cart-bottomCheckout-checkoutButton'
                  >
                    payment is loading...
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RaeShoppingCart;
