import React, { useEffect, useState } from "react";
import { auth, provider } from "../firebase/config";
import { signInWithPopup } from "firebase/auth"; // Import directly from firebase/auth
import "./GoogleSignInButton.css";
import GoogleLogo from "../assets/GoogleLogo.png";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";

const GoogleSignInButton = ({ loginAttempt, setLoginAttempt }) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    try {
      if (user) {
        console.log(`${user.email} is already signed in using Google.`);
        navigate(`/jewelrydesigner`);
      } else {
        const result = await signInWithPopup(auth, provider);
        console.log(`${result.user.email} logged in successfully`);
        setLoginAttempt(loginAttempt + 1);
        navigate(`/jewelrydesigner`, { replace: true });
        console.log("New user authorized with Google.");
        console.log("User:", result.user);
        console.log("forcing page reload");
        window.location.reload();
      }
    } catch (error) {
      console.error("this is the google login error:", error);
    }
  };

  return (
    <div
      id='customGoogleLoginBtn'
      className='customGoogleLoginBtn'
      onClick={() => signInWithGoogle()}
    >
      <span className='icon'>
        <img src={GoogleLogo} />
      </span>
      <span className='buttonText'>Login met Google</span>
    </div>
  );
};

export default GoogleSignInButton;
